var AbsenceSummary;

AbsenceSummary = class AbsenceSummary {
  constructor(el) {
    this.attachDetailEvents = this.attachDetailEvents.bind(this);
    this.initTable = this.initTable.bind(this);
    this.initFilters = this.initFilters.bind(this);
    this.getFilterType = this.getFilterType.bind(this);
    this.fetchAbsenceSummary = this.fetchAbsenceSummary.bind(this);
    this.fetchAbsenceSummaryByFilter = this.fetchAbsenceSummaryByFilter.bind(this);
    this.el = el;
    this.filters = this.el.find("select.selectize");
    this.table = this.el.find("#absence-summaries-results");
    this.currentFilterRequest = 0;
    this.initFilters();
    this.initTable();
    this.attachDetailEvents();
    this.showHideTable();
  }

  attachDetailEvents() {
    return jQuery('#show_teams_and_job_title').on('change', () => {
      return this.showHideTable();
    });
  }

  initTable() {
    jQuery('.excel-export').on('click', (e) => {
      return window.location = `/admin/hrm/absence_summary.xlsx?filter=true&year=${this.el.find('#year').val()}&sequence[]=01-01-${this.el.find('#year').val()}&sequence[]=31-12-${this.el.find('#year').val()}&period=range&team=${this.el.find('#team').val()}&bu=${this.el.find('#bu').val()}&absence_type=${this.el.find('#absence-type').val()}`;
    });
    return $(".absence-summary-datatable").DataTable({
      info: false,
      paging: false,
      searching: false,
      aaSorting: []
    });
  }

  showHideTable() {
    if ($('input#show_teams_and_job_title').is(':checked')) {
      return $('#absence-summaries-results').removeClass('compact');
    } else {
      return $('#absence-summaries-results').addClass('compact');
    }
  }

  initFilters() {
    return this.selectizedElements = this.filters.map((index, field) => {
      return jQuery(field).selectize({
        create: false,
        onChange: (value) => {
          return this.getFilterType(value, event);
        }
      });
    });
  }

  getFilterType(value, event) {
    var dataType, element;
    element = $(event.target);
    dataType = element.closest('.filter').data('type');
    return this.fetchAbsenceSummaryByFilter(value, dataType);
  }

  fetchAbsenceSummary(value, dataType, requestCheck) {
    return $.get('/admin/hrm/absence_summary', {
      filter: 'true',
      year: this.el.find('#year').val(),
      sequence: [`01-01-${this.el.find('#year').val()}`, `31-12-${this.el.find('#year').val()}`],
      period: "range",
      team: this.el.find('#team').val(),
      bu: this.el.find('#bu').val(),
      absence_type: this.el.find('#absence-type').val()
    }, (data) => {
      if (requestCheck === this.currentFilterRequest) {
        this.table.html(data);
        return this.initTable();
      }
    });
  }

  fetchAbsenceSummaryByFilter(value, dataType) {
    var currentPeriod, currentValue, requestCheck;
    $('.page-loader').css('display', 'flex');
    this.currentFilterRequest += 1;
    requestCheck = this.currentFilterRequest;
    if (["year", "bu", "team"].includes(dataType)) {
      currentValue = 0;
      currentPeriod = '';
      this.selectizedElements.each((index, field) => {
        if (!["year", "bu", "team"].includes(field.parent().data('type'))) {
          if (field[0].value !== '') {
            currentValue = field[0].value;
            return currentPeriod = field.parent().data('type');
          }
        }
      });
      return this.fetchAbsenceSummary(currentValue, currentPeriod, requestCheck);
    } else {
      this.selectizedElements.each((index, field) => {
        if (!["year", dataType, 'range', '', void 0, 'bu', 'team'].includes(field.parent().data('type'))) {
          if (field[0].value !== '') {
            return field[0].selectize.setValue('', true);
          }
        }
      });
      return this.fetchAbsenceSummary(value, dataType, requestCheck);
    }
  }

};

$(function() {
  var overview;
  overview = jQuery.find('#absence-summary-overview');
  if (overview.length > 0) {
    return new AbsenceSummary(jQuery(overview));
  }
});
