var InvoicingOverviewAssignment;

window.InvoicingOverviewAssignment = InvoicingOverviewAssignment = class InvoicingOverviewAssignment {
  constructor(assignment, contact) {
    this.attachEventHandlers = this.attachEventHandlers.bind(this);
    this.setDateBorder = this.setDateBorder.bind(this);
    this.updateSelectedStatus = this.updateSelectedStatus.bind(this);
    this.fillDeclaration = this.fillDeclaration.bind(this);
    this.update = this.update.bind(this);
    this.approve = this.approve.bind(this);
    this.defaultData = this.defaultData.bind(this);
    this.refresh = this.refresh.bind(this);
    this.updateRow = this.updateRow.bind(this);
    this.setState = this.setState.bind(this);
    this.isInvoiced = this.isInvoiced.bind(this);
    this.isSelected = this.isSelected.bind(this);
    this.isApproved = this.isApproved.bind(this);
    this.canBeApproved = this.canBeApproved.bind(this);
    this.getId = this.getId.bind(this);
    this.getType = this.getType.bind(this);
    this.getValue = this.getValue.bind(this);
    this.getTimeInterval = this.getTimeInterval.bind(this);
    this.getRate = this.getRate.bind(this);
    this.getSurchargeAmount = this.getSurchargeAmount.bind(this);
    this.getKilometers = this.getKilometers.bind(this);
    this.getNotes = this.getNotes.bind(this);
    this.getHours = this.getHours.bind(this);
    this.getAmount = this.getAmount.bind(this);
    this.assignment = assignment;
    this.contact = contact;
    this.wasSelected = false;
    this.date = this.assignment.data('date');
    this.attachEventHandlers();
    this.updateEndpoint = this.assignment.attr("data-endpoint");
    this.kilometers = this.assignment.parents("table").hasClass("kilometers") && !this.assignment.hasClass("cancelled-with-charge");
  }

  attachEventHandlers() {
    this.assignment.find("#fill-declaration").on('click', (e) => {
      this.fillDeclaration({
        assignment: {
          id: this.assignment.data('id'),
          fill_declaration: true
        }
      });
      return this.assignment.find(".locations").addClass("loading");
    });
    this.checkbox = this.assignment.find('.select-assignment');
    if (this.contact.selectedAssignmentIdsBeforeRender.length > 0 || this.wasSelected) {
      this.checkbox.prop('checked', this.contact.selectedAssignmentIdsBeforeRender.includes(this.getId()) || this.wasSelected);
      this.contact.updateSelectedStatus();
    }
    this.assignment.on('click', (e) => {
      if (!$(e.target).is('a') && !$(e.target).is('i') && !$(e.target).is('textarea') && !$(e.target).is('span') && !($(e.target).is('input') && !$(e.target).is(":disabled")) && !$(e.target).is(".editable-time-range") && !$(e.target).is("#fill-declaration")) {
        this.setState(!this.checkbox.prop('checked'));
      }
      return this.contact.updateSelectedStatus();
    });
    this.assignment.find('a.unapprove-assignment').on('click', (e) => {
      e.preventDefault();
      return this.update({
        assignment: {
          claim_status: ""
        }
      });
    });
    this.assignment.find('a.undismiss-assignment').on('click', (e) => {
      e.preventDefault();
      return this.update({
        assignment: {
          dismissed: 0,
          inv_sent: 0
        }
      }, true);
    });
    this.assignment.find('a.send-invoice').magnificPopup({
      type: 'ajax',
      callbacks: {
        ajaxContentAdded: () => {
          return new InvoicePreview(jQuery('.invoice-preview'), this);
        }
      }
    });
    this.assignment.find('.approve-assignment:not(.manual)').on('click', (e) => {
      e.preventDefault();
      return this.approve($(e.target));
    });
    this.assignment.find(".copy-distance").on('click', (e) => {
      var distance;
      distance = Math.round(parseFloat(jQuery(e.target).attr("data-distance")));
      jQuery(e.target).closest("tr").find("td.distance input").val(distance).blur();
      return e.preventDefault();
    });
    this.assignment.find("input.fake").on('blur', (e) => {
      var hours, minutes;
      hours = jQuery(e.target).parent().find("input[name='hours']").val();
      minutes = jQuery(e.target).parent().find("input[name='minutes']").val();
      return jQuery(e.target).siblings("input.hidden").attr("value", hours + ":" + minutes + ":00").blur();
    });
    this.assignment.find('input[type="text"]:not(.fake)').on('blur', (e) => {
      return this.update(this.defaultData());
    });
    this.assignment.find('input[type="number"].custom-rate').on('blur', (e) => {
      return this.update({
        assignment: {
          id: this.assignment.data('id'),
          inv_rate: this.getRate()
        }
      });
    });
    this.assignment.find('input[type="number"].surcharge-amount').on('blur', (e) => {
      return this.update({
        assignment: {
          id: this.assignment.data('id'),
          custom_inv_charge_driving: this.getSurchargeAmount()
        }
      });
    });
    this.assignment.find('textarea.remark').on('blur', (e) => {
      return this.update(this.defaultData());
    });
    this.assignment.find('.undo-assignment-changes').on('click', (e) => {
      return this.update($(e.currentTarget).data('originals'));
    });
    this.assignment.find('input').on('focus', (e) => {
      return this.inputWithFocus = jQuery(e.target).attr("name");
    });
    return this.assignment.find('.editable-time-range').on('click', (e) => {
      if ($(e.target).is('input') || $(e.target).is('i')) {
        return;
      }
      return $($(e.currentTarget).find('input')[0]).focus();
    });
  }

  setDateBorder() {
    if (this.contact.isLastOnDate(this)) {
      return this.assignment.addClass('last-of-date');
    }
  }

  updateSelectedStatus() {
    if (this.isSelected()) {
      return this.assignment.addClass('selected');
    } else {
      return this.assignment.removeClass('selected');
    }
  }

  fillDeclaration(data) {
    return $.ajax({
      url: this.updateEndpoint + ".partial",
      method: "PATCH",
      data: data,
      success: (data) => {
        this.updateRow(data);
        if (this.inputWithFocus) {
          this.assignment.find("[name='" + this.inputWithFocus + "']").focus();
        }
        this.inputWithFocus = null;
        return this.assignment.find(".locations").removeClass("loading");
      },
      error: (response) => {
        this.contact.handleError();
        return this.assignment.find(".locations").removeClass("loading");
      }
    });
  }

  update(data, reloadTable = false) {
    return $.ajax({
      url: this.updateEndpoint + ".partial",
      method: "PATCH",
      data: data,
      success: (data) => {
        if (reloadTable) {
          return this.contact.refresh();
        } else {
          this.updateRow(data);
          if (this.inputWithFocus) {
            this.assignment.find("[name='" + this.inputWithFocus + "']").focus();
          }
          return this.inputWithFocus = null;
        }
      },
      error: (response) => {
        return this.contact.handleError();
      }
    });
  }

  approve(target) {
    return $.ajax({
      url: `${target.attr('href').substring(0, target.attr('href').indexOf('/edit'))}.partial?commit=approve`,
      method: 'PUT',
      data: this.defaultData(),
      success: (data) => {
        return this.updateRow(data);
      },
      error: (response) => {
        return this.contact.handleError();
      }
    });
  }

  defaultData() {
    var indexed_array, unindexed_array;
    if (this.kilometers) {
      unindexed_array = this.assignment.find("form").serializeArray();
      indexed_array = {};
      $.map(unindexed_array, function(n, i) {
        indexed_array[n['name']] = n['value'];
      });
      return indexed_array;
    } else {
      return {
        assignment: {
          id: this.assignment.data('id'),
          inv_start_time: this.getTimeInterval("start"),
          inv_finish_time: this.getTimeInterval("finish"),
          inv_pause_start_time: this.getTimeInterval("break_start"),
          inv_pause_finish_time: this.getTimeInterval("break_finish"),
          assignment_notes: this.getNotes("assignment-notes"),
          office_notes: this.getNotes("office-notes"),
          inv_notes: this.getNotes("inv-notes"),
          inv_total_kilometres: this.getKilometers()
        }
      };
    }
  }

  refresh() {
    return $.ajax({
      url: `/admin/finance/invoicing/overview/${this.getId()}.partial`,
      method: 'GET',
      success: (data) => {
        return this.updateRow(data);
      },
      error: () => {
        return this.contact.handleError();
      }
    });
  }

  updateRow(data) {
    this.wasSelected = this.isSelected();
    this.assignment.replaceWith(data);
    this.assignment = $(`tr[data-id=${this.getId()}]`);
    jQuery.magnificPopup.close();
    this.attachEventHandlers();
    this.setDateBorder();
    if (!this.wasSelected) {
      return this.checkbox.prop('checked', false);
    }
  }

  setState(checked) {
    return this.checkbox.prop('checked', checked);
  }

  isInvoiced() {
    return this.assignment.hasClass("invoiced");
  }

  isSelected() {
    return this.checkbox.prop('checked');
  }

  isApproved() {
    return this.assignment.hasClass('approved');
  }

  canBeApproved() {
    return this.assignment.find(".approve-assignment:not(.manual)").length > 0;
  }

  getId() {
    return this.assignment.data('id');
  }

  getType() {
    return this.assignment.find("span.label").attr("data-type");
  }

  getValue(value) {
    return this.assignment.find("[data-type='" + value + "']").html();
  }

  getTimeInterval(prefix) {
    return (this.assignment.find("input[name='" + prefix + "_time_hours']").val() || "00") + ":" + (this.assignment.find("input[name='" + prefix + "_time_minutes']").val() || "00") + ":00";
  }

  getRate() {
    return this.assignment.find("input.custom-rate").val();
  }

  getSurchargeAmount() {
    return this.assignment.find("input.surcharge-amount").val();
  }

  getKilometers() {
    return this.assignment.find("input.kilometers").val();
  }

  getNotes(note) {
    return this.assignment.find(`textarea.remark.${note}`).val();
  }

  getHours(type) {
    var hours, minutes, value, values;
    value = this.assignment.find("td[data-type='" + type + "']").html();
    if (value) {
      values = value.split(":");
      hours = parseInt(values[0]);
      minutes = values.length > 1 ? parseInt(values[1]) : 0;
      hours += minutes / 60;
      return hours;
    }
  }

  getAmount(type) {
    return parseFloat(this.assignment.attr("data-" + type));
  }

};
