var LocationsPlanner;

window.LocationsPlanner = LocationsPlanner = class LocationsPlanner {
  constructor($el) {
    this.initInputs = this.initInputs.bind(this);
    this.attachEventHandlers = this.attachEventHandlers.bind(this);
    this.moveBefore = this.moveBefore.bind(this);
    this.setTripOrder = this.setTripOrder.bind(this);
    this.$el = $el;
    this.attachEventHandlers(this.$el);
    this.initInputs(this.$el);
  }

  initInputs(scope) {
    var pickers;
    pickers = scope.find("input.timepicker");
    $.each(pickers, (index, picker) => {
      return $(picker).timepicker({
        timeFormat: "HH:mm",
        interval: 15,
        startTime: "00:00",
        dynamic: false,
        dropdown: true,
        scrollbar: true,
        zindex: 1045
      });
    });
    return $(".timepicker").attr("autocomplete", "off");
  }

  attachEventHandlers(scope) {
    scope.on('click', '.move-location-up', (e) => {
      var row;
      row = $(e.target).closest('tr');
      if (!(row.length && row.prev().length)) {
        return;
      }
      row.hide().show('slow');
      return this.moveBefore(row, row.prev());
    });
    scope.on('click', '.move-location-down', (e) => {
      var row;
      row = $(e.target).closest('tr');
      if (!(row.length && row.next().length)) {
        return;
      }
      row.hide().show('slow');
      return this.moveBefore(row.next(), row);
    });
    scope.on('nested:fieldAdded', (e) => {
      this.setTripOrder();
      this.initInputs($(e.target));
      if ($(e.target).find('.autocomplete').length > 0) {
        return new locationAutocomplete($(e.target).find('.autocomplete'));
      }
    });
    scope.on('nested:fieldRemoved', (e) => {
      // Remove deleted rows from the schedule table to allow ordering
      return $(e.target).insertAfter($('.service-form'));
    });
    $('.add-common-row').on('click', (e) => {
      var data;
      data = $(e.target).data();
      return $.ajax({
        url: window.location.pathname + `/schedules.js?id=${data.id}&object=${data.type}`,
        method: 'POST',
        success: (response) => {
          var newRow;
          // Make the newly added custom row match with potential client-side added rows
          newRow = scope.find('tr.fields').last();
          this.initInputs(newRow);
          this.setTripOrder();
          return newRow.find('input, textarea').each((index, input) => {
            $(input).attr('name', $(input).attr('name').replace('[0]', `[${newRow.data('id')}]`));
            return $(input).attr('id', $(input).attr('id').replace('_0_', `_${newRow.data('id')}_`));
          });
        }
      });
    });
    return scope.on('click', '.remove-row-manual', (e) => {
      // Dynamically adding custom rows isn't supported in fields_for,
      // so we have to implement the removal manually
      return $(e.target).closest('tr').remove();
    });
  }

  moveBefore(moving, target) {
    moving.insertBefore(target);
    return this.setTripOrder();
  }

  setTripOrder() {
    return $('.trip-order').each(function(index, trip) {
      return $(trip).val(index);
    });
  }

};

$(function() {
  if ($("table.locations-planner").length > 0) {
    return new LocationsPlanner($("table.locations-planner"));
  }
});
