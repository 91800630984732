$(function() {
  return $(".datatable").DataTable({
    info: false,
    paging: false,
    searching: false,
    aaSorting: [],
    aoColumnDefs: [
      {
        bSortable: false,
        aTargets: [-1]
      }
    ]
  });
});
