$(document).ready(function () {
  $(document).on("click", ".magnific-popup-link", function (e) {
    e.preventDefault()
    const popupId = `#${jQuery(e.currentTarget).attr("id")}-popup`
    jQuery.magnificPopup.open({
      items: {src: popupId},
      callbacks: {
        open: function () {
          jQuery(`${popupId} select.selectize:not(.selectized)`).selectize()
        },
      },
    })
  })

  // Temp fix for magnific popup close issue
  $(document).on("click", ".mfp-close", function (e) {
    e.preventDefault()
    $.magnificPopup.close()
  })
})
