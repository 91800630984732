var ReportingFilterBar, replaceDirectionIcons;

ReportingFilterBar = class ReportingFilterBar {
  constructor(overview) {
    this.initSelectFields = this.initSelectFields.bind(this);
    this.attachHandlers = this.attachHandlers.bind(this);
    this.updateData = this.updateData.bind(this);
    this.overview = overview;
    this.initSelectFields(jQuery(this.overview));
    this.attachHandlers();
    this.params = new URLSearchParams((new URL(window.location.href)).search);
    if (this.overview.data('sort')) {
      this.params.set("sort", this.overview.data('sort'));
    }
  }

  initSelectFields(within) {
    within.find(".selectize").selectize({
      render: {
        item: replaceDirectionIcons,
        option: replaceDirectionIcons
      }
    });
    return within.find(".selectize-multiple").selectize({
      maxItems: null
    });
  }

  attachHandlers() {
    jQuery(this.overview).find("#filters select").on("change", (event) => {
      if ($(event.target).hasClass('add_as_class')) {
        jQuery(this.overview).find("#filters").removeClass();
        jQuery(this.overview).find("#filters").addClass($(event.target).val());
      }
      return jQuery(this.overview).find("#filters").submit();
    });
    return jQuery(this.overview).on("submit", "#filters", (event) => {
      var filter, filters, i, len;
      event.preventDefault();
      filters = jQuery("#filters").serializeArray();
      for (i = 0, len = filters.length; i < len; i++) {
        filter = filters[i];
        this.params.set(filter.name, filter.value);
      }
      return this.updateData();
    });
  }

  updateData() {
    var exportPath, path;
    $(".rails-container > .page-loader").css("display", "flex");
    if (document.getElementById('export-filtered-data')) {
      exportPath = new URL(window.location.href).pathname + ".xlsx?" + this.params.toString();
      document.getElementById('export-filtered-data').href = exportPath;
    }
    path = new URL(window.location.href).pathname + "?" + this.params.toString();
    window.history.pushState(path, jQuery("head title").html(), path);
    return jQuery.get(path + "&filter=true").done((data, textStatus, jqXHR) => {
      jQuery(".with-filter-bar").find(".report").replaceWith(data);
      return $(".page-loader").css("display", "none");
    });
  }

};

jQuery(function() {
  if (jQuery(".with-filter-bar").length > 0) {
    return new ReportingFilterBar(jQuery(".with-filter-bar"));
  }
});

// This exists because we can't specify icons in the <option> elements.
replaceDirectionIcons = function(item, escape) {
  var label;
  label = escape(item.text);
  label = label.replace(/^(.*) \(ascending\)$/, '<i class="fas fa-sort-amount-up"/> $1');
  label = label.replace(/^(.*) \(descending\)$/, '<i class="fas fa-sort-amount-down"/> $1');
  return `<div>${label}</div>`;
};
