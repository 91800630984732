$(function() {
  return jQuery(".assignments_overview th > a").click(function() {
    var direction, name, order_by;
    order_by = jQuery(".assignments_overview form #order_by");
    direction = jQuery(".assignments_overview form #order_direction");
    name = jQuery(this).text().toLowerCase();
    if (name === order_by.val()) {
      if (direction.val() === "ascending") {
        direction.val("descending");
      } else {
        direction.val("ascending");
      }
    } else {
      order_by.val(name);
      direction.val("descending");
    }
    return jQuery(".assignments_overview form").submit();
  });
});
