var AssignmentForm;

import moment from "moment";

AssignmentForm = class AssignmentForm {
  constructor($el) {
    this.attachEventHandlers = this.attachEventHandlers.bind(this);
    this.initHover = this.initHover.bind(this);
    this.initInputs = this.initInputs.bind(this);
    this.renderServiceForm = this.renderServiceForm.bind(this);
    this.sendConfirmationEmail = this.sendConfirmationEmail.bind(this);
    this.employeeDropdownContent = this.employeeDropdownContent.bind(this);
    // <span class='contract-hours-surplus #{if item.contract_hours_surplus_month >= 0 then "open" else "closed"}'>#{escape(item.contract_hours_surplus_month)}</span>
    this.loading = this.loading.bind(this);
    this.$el = $el;
    this.assignmentId = this.$el.attr("data-id");
    this.attachEventHandlers();
    this.initInputs(this.$el);
    this.initHover();
    if (parseInt($('.selectize-bu').val()) === 3) { // Beveiligingsdiensten
      // Show surcharge warning for security assignments that haven't been saved yet.
      $("#surcharge-warning").show();
    }
  }

  attachEventHandlers() {
    $('.log-button').on('click', (e) => {
      return jQuery.magnificPopup.open({
        items: {
          src: "#log-container"
        }
      });
    });
    $('.selectize-contact').on('change', (e) => {
      if (!$(e.target).val().length) {
        return;
      }
      return this.renderServiceForm($('.selectize-bu').val(), $(e.target).val());
    });
    $('.selectize-bu').on('change', (e) => {
      if (!$(e.target).val().length) {
        return;
      }
      if (!$('.selectize-contact').val().length) {
        return;
      }
      return this.renderServiceForm($(e.target).val(), $('.selectize-contact').val());
    });
    $('.selectize-client').on('change', (e) => {
      if (!$(e.target).val().length) {
        return;
      }
      if (!$('.selectize-contact').val().length) {
        return;
      }
      return this.renderServiceForm($('.selectize-bu').val(), $('.selectize-contact').val());
    });
    if ($('.input-disabled').length) {
      $('.send-employee-email').addClass('disabled');
      $('.send-employee-email').removeAttr('href data-endpoint');
      $('#employee_email').prop('disabled', true);
    } else {
      $('.send-employee-email').on('click', (e) => {
        $(e.target).addClass('loading');
        e.preventDefault();
        return this.sendConfirmationEmail($(e.target));
      });
    }
    $('.send-contact-email').on('click', (e) => {
      $(e.target).addClass('loading');
      e.preventDefault();
      return this.sendConfirmationEmail($(e.target), '&preliminary_confirmation=' + $('#preliminary').is(":checked"));
    });
    $('form').on('keydown', (e) => {
      if (e.keyCode === 13 && !$(e.target).is('textarea')) {
        return e.preventDefault();
      }
    });
    $('.create-new-assignment').on('click', (e) => {
      return $('.create-new-assignment').css('pointer-events', 'none');
    });
    $('form').on('click', 'input[type="submit"]', (e) => {
      var form;
      form = $(e.target).parents('form');
      // We currently don't support showing the surcharge popup when the assignment isn't saved yet.
      if (form.hasClass('edit_assignment_form') && window.applicableSurcharge && form[0].checkValidity()) {
        e.preventDefault();
        return window.applicableSurcharge.checkApplicableSurcharge(this.assignmentId, false, $("[name='assignment[employee_id]']").val(), $("[name='assignment[date]']").val(), $("[name='assignment[start_time]']").val(), $("[name='assignment[finish_time]']").val(), () => {
          return form.submit();
        });
      }
    });
    // Prevent multiple form submission
    $('form').on('submit', function(e) {
      return $('form').on('submit', function(e) {
        return false;
      });
    });
    $('.time-input input').on('change', (e) => {
      if (!moment($(e.target).val(), ["HH:mm", "HHmm", "HH", "H"], true).isValid() && !$(e.target).hasClass("finish_time")) {
        return $(e.target).val("");
      } else {
        if (!$(e.target).hasClass("finish_time")) {
          // always format user input to "HH:mm"
          return $(e.target).val(moment($(e.target).val(), "HH:mm").format("HH:mm"));
        }
      }
    });
    $('.info-link').on('click', (e) => {
      var infoPage;
      infoPage = window.open($(e.currentTarget).data('link'), '_blank');
      return infoPage.focus();
    });
    $('#add-manual-surcharge').on('click', (e) => {
      return jQuery.magnificPopup.open({
        items: {
          src: '#manual-surcharge-popup'
        },
        type: 'inline',
        callbacks: {
          open: () => {
            return this.initInputs($('#manual-surcharge-popup'));
          }
        }
      });
    });
    return $('table.surcharges').on('click', 'a.delete', (e) => {
      var url;
      e.preventDefault();
      url = $(e.currentTarget).attr('href');
      if (url) { // just to make sure that if no href is found that it won't delete the entire assignment :)
        return $.ajax({
          url: $(e.currentTarget).attr('href'),
          type: 'delete',
          success: (data) => {
            return $(e.currentTarget).parents('tr').remove();
          }
        });
      }
    });
  }

  initHover() {
    $(".datepicker-grid").datepicker({
      dateFormat: 'yy-mm-dd',
      numberOfMonths: 3,
      showCurrentAtPos: 1,
      beforeShow: function() {
        return $("#ui-datepicker-div").addClass("datepicker-grid-pop-up");
      },
      onSelect: function(dateText) {
        return window.location = "/admin/operations/dashboard?preferences[date]=" + dateText;
      }
    });
    $(".datepicker-trigger").hover(function() {
      $(".datepicker-grid").datepicker("show");
      return $("#ui-datepicker-div").append("<div class=\"arrow\"></div>");
    });
    if ($("table.assignments-locations-planner").length > 0) {
      return new LocationsPlanner($("table.assignments-locations-planner"));
    }
  }

  initInputs(scope) {
    var employee_select, pickers;
    scope.find("input.datepicker").datepicker({
      beforeShow: function() {
        return $('#ui-datepicker-div').removeClass("datepicker-grid-pop-up");
      }
    });
    $(".datepicker").attr("autocomplete", "off");
    pickers = scope.find("input.timepicker");
    $.each(pickers, (index, picker) => {
      return $(picker).timepicker({
        timeFormat: "HH:mm",
        interval: 15,
        startTime: "00:00",
        dynamic: false,
        dropdown: true,
        scrollbar: true,
        zindex: 1045 // Magic number to make sure it also works in magnificPopups.
      });
    });
    $(".timepicker").attr("autocomplete", "off");
    scope.find("select.selectize").selectize({
      allowEmptyOption: true,
      create: false
    });
    scope.find("select.selectize-non-empty").selectize({
      allowEmptyOption: false,
      create: false
    });
    scope.find("select.selectize-contact").selectize({
      allowEmptyOption: false,
      create: false,
      searchField: ['contact', 'company'],
      maxOptions: 100,
      render: {
        option: function(item, escape) {
          if (!item.contact) {
            return "<div class='select-contact-option empty'></div>";
          } else {
            return `<div class='select-contact-option'> ${escape(item.contact)} <div class='company'> ${(item.company ? `${escape(item.company)}` : void 0) || ''} </div> <div class='bu'> ${escape(item.bu)} </div> </div>`;
          }
        }
      }
    });
    return employee_select = scope.find("select.selectize-employee").selectize({
      allowEmptyOption: false,
      create: false,
      labelField: 'name',
      valueField: 'employeeid',
      searchField: ['name', 'city', 'job'],
      maxOptions: 100,
      render: {
        option: (item, escape) => {
          return this.employeeDropdownContent(item, escape);
        },
        item: (item, escape) => {
          return this.employeeDropdownContent(item, escape);
        }
      },
      load: (query, callback) => {
        if (!(query.length > 1)) {
          return callback();
        }
        this.loading(true);
        return $.ajax({
          url: $('#assignment_employee_id').data('endpoint') + `&search=${encodeURIComponent(query)}` + `&date=${encodeURIComponent($("#assignment_date").val())}` + `&client=${encodeURIComponent($("#assignment_clientid").val())}`,
          error: function() {
            return callback();
          },
          success: (response) => {
            this.loading(false);
            return callback(response.employees.slice(0, 100));
          }
        });
      }
    });
  }

  renderServiceForm(buId, contactId) {
    this.loading(true);
    return $.ajax({
      url: window.location.pathname + `?contact=${contactId}&` + (buId ? `bu=${buId}` : void 0),
      data: $('.edit_assignment_form').serialize(),
      success: (response) => {
        jQuery('.assignment-form').replaceWith(response);
        new AssignmentForm($(".assignment-form"));
        return this.loading(false);
      }
    });
  }

  sendConfirmationEmail(confirmation, options = '') {
    return $.ajax({
      url: confirmation.data('endpoint') + options + '&confirm_address=' + confirmation.prev().val(),
      type: 'POST',
      success: (response) => {
        return location.reload();
      }
    });
  }

  employeeDropdownContent(item, escape) {
    var preference;
    if (item.preferred !== null) {
      preference = `<i class='fas ${item.preferred ? 'fa-star preferred' : 'fa-exclamation-triangle not-preferred'}'></i>`;
    } else {
      preference = '';
    }
    return `<div class='select-employee-option'> <span class='name'> <a href='/admin/hrm/employees/${escape(item.id)}' class='employee-link' target='_blank'><i class='fas fa-link' /></a> ${escape(item.name)}${preference} </span> <span class='job'>${escape(item.job)}</span> <span class='city'>${escape(item.city)}</span> <span class='mobile'>${escape(item.phone)}</span> <span class='contract-hours-surplus ${item.contract_hours_surplus_week >= 0 ? "open" : "closed"}'>${escape(item.contract_hours_surplus_week)}</span> <span class='assignments-done'>${escape(item.assignments_done)}</span> <span class='availabilities'> <availability-blocks props-json='{\"employee\": ${JSON.stringify(item)}, \"availabilities\": ${JSON.stringify(item.availabilities)}, \"currentDate\": \"${moment($("[name='assignment[date]']").val(), "DD-MM-YYYY").format("YYYY-MM-DD")}\" }' /> </span> </div>`;
  }

  loading(state) {
    if (state) {
      return $(document).find('.double-spinner').css('display', 'inline-block');
    } else {
      return $(document).find('.double-spinner').css('display', 'none');
    }
  }

};

$(function() {
  if ($(".assignment-form").length > 0) {
    return new AssignmentForm($(".assignment-form"));
  }
});
