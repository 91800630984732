$.datepicker.setDefaults({
  dateFormat: "dd-mm-yy",
  firstDay: 1
});

$(function() {
  if (typeof setupZapatecCalendar !== "undefined" && setupZapatecCalendar !== null) {
    setupZapatecCalendar();
  }
  if (typeof setupZapatecDateField !== "undefined" && setupZapatecDateField !== null) {
    setupZapatecDateField();
  }
  if (typeof setupTinyMCE !== "undefined" && setupTinyMCE !== null) {
    setupTinyMCE();
  }
  if (typeof loadContracts !== "undefined" && loadContracts !== null) {
    return loadContracts();
  }
});
