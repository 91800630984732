var HoursSummary;

HoursSummary = class HoursSummary {
  constructor(el) {
    this.attachDetailEvents = this.attachDetailEvents.bind(this);
    this.initTable = this.initTable.bind(this);
    this.initFilters = this.initFilters.bind(this);
    this.getFilterType = this.getFilterType.bind(this);
    this.fetchHours = this.fetchHours.bind(this);
    this.updateCustomRange = this.updateCustomRange.bind(this);
    this.fetchHoursSummaryByFilter = this.fetchHoursSummaryByFilter.bind(this);
    this.el = el;
    this.filters = this.el.find("select.selectize");
    this.table = this.el.find("#hours-summaries-results");
    this.datePickers = this.el.find(".datepicker");
    this.currentFilterRequest = 0;
    this.initFilters();
    this.initTable();
    this.initializeDatePickers();
    this.attachDetailEvents();
  }

  attachDetailEvents() {
    jQuery('.datepicker').on('change', (e) => {
      if (this.datePickers[0].value && this.datePickers[1].value) {
        return this.getFilterType([this.datePickers[0].value, this.datePickers[1].value], $(e.target));
      }
    });
    return jQuery('#difference').on('change', () => {
      return this.showHideTable();
    });
  }

  initTable() {
    //TODO: Make this more DRY
    jQuery('.excel-export').on('click', (e) => {
      var period, sequence;
      if (this.el.find('#period').val()) {
        sequence = `[]=${this.el.find('#period').val().join("&sequence[]=")}`;
        period = 'fourweek';
      } else if (this.el.find('#month').val()) {
        sequence = `[]=${this.el.find('#month').val().join("&sequence[]=")}`;
        period = 'month';
      } else {
        sequence = `[]=${this.datePickers[0].value}&sequence[]=${this.datePickers[1].value}`;
        period = 'range';
      }
      return window.location = `/admin/hrm/employee_hours.xlsx?filter=true&year=${this.el.find('#year').val()}&sequence${sequence}&period=${period}&team=${this.el.find('#team').val()}&bu=${this.el.find('#bu').val() || ""}&job_title=${this.el.find('#job_title').val() || ""}`;
    });
    $(".hours-summary-datatable").DataTable({
      info: false,
      paging: false,
      searching: false,
      aaSorting: []
    });
    return this.showHideTable();
  }

  showHideTable() {
    if ($('input#difference').is(':checked')) {
      return $('table.hours-summary').addClass('compact');
    } else {
      return $('table.hours-summary').removeClass('compact');
    }
  }

  initFilters() {
    this.selectizedElements = this.filters.map((index, field) => {
      if ($(field).hasClass("multiple")) {
        return jQuery(field).selectize({
          create: false,
          maxItems: null,
          onBlur: (value) => {
            return this.getFilterType($(field).val(), $(field));
          }
        });
      } else {
        return jQuery(field).selectize({
          create: false,
          onBlur: (value) => {
            return this.getFilterType($(field).val(), $(event.target));
          }
        });
      }
    });
    return jQuery('#refresh-result').on('click', (e) => {
      return this.fetchHoursSummaryByFilter(this.el.find('#year').val(), 'year');
    });
  }

  initializeDatePickers() {
    return this.datePickers.each((index, field) => {
      return jQuery(field).datepicker('destroy').datepicker({
        changeYear: false,
        dateFormat: 'dd-mm-yy',
        minDate: `01-01-${this.el.find('#year').val()}`,
        maxDate: `31-12-${this.el.find('#year').val()}`
      });
    });
  }

  getFilterType(value, element) {
    var dataType;
    dataType = element.closest('.filter').data('type');
    return this.fetchHoursSummaryByFilter(value, dataType);
  }

  fetchHours(value, dataType, requestCheck) {
    return $.get('/admin/hrm/employee_hours', {
      filter: 'true',
      year: this.el.find('#year').val(),
      sequence: value,
      period: dataType,
      team: this.el.find('#team').val(),
      job_title: this.el.find('#job_title').val(),
      bu: this.el.find('#bu').val()
    }, (data) => {
      if (requestCheck === this.currentFilterRequest) {
        this.table.html(data);
        this.updateCustomRange();
        this.initializeDatePickers();
        return this.initTable();
      }
    });
  }

  updateCustomRange() {
    $('#custom-range-from').val($('#current-results-range').data('from'));
    return $('#custom-range-end').val($('#current-results-range').data('end'));
  }

  fetchHoursSummaryByFilter(value, dataType) {
    var currentPeriod, currentValue, requestCheck;
    $('.page-loader').css('display', 'flex');
    this.currentFilterRequest += 1;
    requestCheck = this.currentFilterRequest;
    if (["year", "bu", "team", "job_title"].includes(dataType)) {
      currentValue = 0;
      currentPeriod = '';
      this.selectizedElements.each((index, field) => {
        if (!["year", "bu", "team", "job_title"].includes(field.parent().data('type'))) {
          if (field[0].value !== '') {
            currentValue = field[0].value;
            return currentPeriod = field.parent().data('type');
          }
        }
      });
      return setTimeout((() => {
        return this.fetchHours(currentValue, currentPeriod, requestCheck);
      }), 1);
    } else {
      this.selectizedElements.each((index, field) => {
        if (!["year", dataType, 'range', '', void 0, 'bu', 'job_title', 'team'].includes(field.parent().data('type'))) {
          if (field[0].value !== '') {
            return field[0].selectize.setValue('', true);
          }
        }
      });
      return this.fetchHours(value, dataType, requestCheck);
    }
  }

};

$(function() {
  var overview;
  overview = jQuery.find('#hours-summary-overview');
  if (overview.length > 0) {
    return new HoursSummary(jQuery(overview));
  }
});
