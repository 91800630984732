
var startFinishTimeInputs = ["input#start_time0", "input#start_time1", "input#finish_time0", "input#finish_time1"];

function setClient(id,name,notes) {
    if (!notes && notes != '') {
        notes = document.getElementById('clnt_sched_notes'+id).value;
    }
    if (id == 0) {
        document.getElementById('client').innerHTML = name;
    } else {
        document.getElementById('client').innerHTML = '<a href="?o=client&a=dashboard&clientid='+id+'" onclick="ajax_list_search=false;">'+name+'</a> [change]';
    }
    document.getElementById('clientid').value = id;
    document.getElementById('client_tip').innerHTML = 'Save assignment to update details';
    document.getElementById('schedulenotes').value = notes;
    if (notes != '') {
        setDisplay('client_notes','table-row');
    } else {
        setDisplay('client_notes','none');
    }
    document.getElementById('clientnote').innerHTML = 'Save assignment to refresh note';
}

function setContact(id,name,notes) {
    if (id == 0) {
        document.getElementById('contact').innerHTML = name;
    } else {
        document.getElementById('contact').innerHTML = '<a href="?o=contact&a=dashboard&contactid='+id+'">'+name+'</a> [change]';
    }
    document.getElementById('contact_tip').innerHTML = 'Save assignment to update details';
    document.getElementById('contactid').value = id;
    document.getElementById('contactnote').innerHTML = 'Save assignment to refresh note';
}

function setCompany(id,name) {
    document.getElementById('company').innerHTML = name;
    document.getElementById('companyid').value = id;
    document.getElementById('company_tip').innerHTML = 'Save assignment to update details';
}

function setEmployee(id, name) {
    var newHtml;
    if (id == 0) {
        newHtml = name;
    } else {
        newHtml = '<a href="/admin/hrm/employees/'+id+'" onclick="ajax_list_search=false;">'+name+'</a> [change]';
    }
    $("#employee").html(newHtml)
    $("#employee_tip").html('Save assignment to update details');
    $("#employeeid").val(id);
    $("#employeenote").html('Save assignment to refresh note')

    employeeSelectableCheckbox();
}

function setVenue(id,name) {
    document.getElementById('venue').innerHTML = name+' [change]';
    document.getElementById('venue_tip').innerHTML = 'Save assignment to update details';
    document.getElementById('venueid').value = id;
}

function addNewLocation() {
    sendRec('locationid='+document.getElementById('new_locationid').value+'&assignmentid='+document.getElementById('assignmentid').value,'schedule','add','schedule', initScheduleEvents);
}

function ajax_clientlist() {
    contactid = document.getElementById('contactid').value;
    if(contactid != '' && ajax_list_search){
        ajax_list_search = setTimeout( "sendRec('contactid="+contactid+"','client','by_contact','client_search_results')",300);
    }
}

function ajax_venuelist() {
    companyid = document.getElementById('companyid').value;
    if(companyid != '' && ajax_list_search){
        ajax_list_search = setTimeout( "sendRec('companyid="+companyid+"','venue','search','venue_search_results')",300);
    }
}

function ajax_employeelist() {
    contactid = document.getElementById('contactid').value;
    clientid = document.getElementById('clientid').value;
    date = document.getElementById('date').value;

    if (contactid != '' && ajax_list_search) {
        sendRec('contactid=' + contactid + '&clientid=' + clientid + '&date=' + date, 'employee', 'by_contact', 'employee_search_results');
    }
}

function ajax_locationlist() {
    contactid = document.getElementById('contactid').value;
    assignmentid = document.getElementById('assignmentid').value;

    if (contactid != '') {
        sendRec('contactid=' + contactid + '&assignmentid=' + assignmentid,'location', 'by_contact', 'location_search_results');
    }
}

// from here the AJAX select

function togglePause(business_unit_id) {
    toggleField("businessUnitPresetPause", business_unit_id, ["#pause_start", "#pause_finish", "#pause_start_label", "#pause_finish_label"]);
}

function togglePredefined(business_unit_id) {
    toggleField("businessUnitPredefinedExpenses", business_unit_id, ["#predefined_expenses_check", "#predefined_expense", "#start_hours_label", "#start_hours", "#finish_hours_label", "#finish_hours"]);
}

function toggleCar(business_unit_id) {
    toggleField("businessUnitPresetCar", business_unit_id, ["#car_row"]);
}

function toggleLocation(business_unit_id) {
    toggleField("businessUnitVenues", business_unit_id, ["#venue_row"]);
}

function toggleClient(business_unit_id) {
    toggleField("businessUnitClient", business_unit_id, ["#client_row"]);
}

function toggleAutoScheduleTimes(business_unit_id) {
    // only do this for saved assignments
    var isNew = $("input#assignmentid").val() == "";
    if (!isNew) {
        toggleField("businessUnitAutoScheduleTimes", business_unit_id, ["#auto_schedule_times"], function() {
            if ($("input#autoscheduletimes").is(":checked")) {
                enableStartFinishTimes();
            } else {
                // NOTE: we could automatically update the times here as well?
                disableStartFinishTimes();
            }
        });
    }
}

function disableStartFinishTimes() {
    $.each(startFinishTimeInputs, function() {
        var timeBox = $(""+this);
        timeBox.attr("readonly", "readonly");
        timeBox.css({ "background-color": "#eee", "border-color": "#eee" });
    });
}

function enableStartFinishTimes() {
    $.each(startFinishTimeInputs, function() {
        var timeBox = $(""+this);
        timeBox.removeAttr("readonly");
        timeBox.css({ "background-color": "#fff", "border-color": "#999" })
    });
}

function toggleField(action, business_unitid, fields, callback) {
    url = "extranet.php?a=" + action + "&o=assignment&bid=" + business_unitid;
    $.get(url, function(data) {
        var result = (data.includes('1')) ? true : false;

        for (var i = 0; i < fields.length; i++)    {
            (result) ? $(fields[i]).show() : $(fields[i]).hide();
        }
        if (callback) {
            callback();
        }
    });
}

// enable of disable items on de assignment page
function disableitems(items) {
    if(items == 'business_unitid') {
        var business_unitid = $("#business_unitid").val();
        togglePause(business_unitid);
        togglePredefined(business_unitid);
        toggleCar(business_unitid);
        toggleLocation(business_unitid);
        toggleClient(business_unitid);
        toggleAutoScheduleTimes(business_unitid);
    }
}

// http://www.ajaxray.com/blog/2007/11/08/jquery-controlled-dependent-or-cascading-select-list-2/
function makeSublist(parent,child,isSubselectOptional,childVal) {
    $("body").append("<select style='display:none' id='"+parent+child+"'></select>");
    $('#'+parent+child).html($("#"+child+" option"));
    var parentValue = $('#'+parent).attr('value');
    var initialParentValue = parentValue;
    $('#'+child).html($("#"+parent+child+" .sub_"+parentValue).clone());
    $('#'+parent).change(
        function()
        {
            var parentValue = $('#'+parent).attr('value');
            $('#'+child).html($("#"+parent+child+" .sub_"+parentValue).clone());
            if (isSubselectOptional) {
                selected = (parentValue != initialParentValue || childVal == 0) ? "selected" : "";
                $('#'+child).prepend("<option value='0' " + selected + ">  </option>");
            }
            $('#'+child).trigger("change");
            $('#'+child).focus();
        }
    );
}

$(function() {
    // handle 'employee selectable' checkbox
    employeeSelectableCheckbox();

    // handle enter button of location form
    $("div.newlocation input").on("keypress", function(e) {
        var k = e.keyCode || e.which;
        if (k == 13) {
            $("div.newlocation input.location_submit").click();
            return false;
        }
    });
});

function employeeSelectableCheckbox() {
    var checkboxDiv = $("table.editassignment td.employeesearch div.selectable");
    if ($("input#employeeid").val() == "0") {
        checkboxDiv.show();
    } else {
        checkboxDiv.hide();
    }
}

function initScheduleEvents() {
    $("table.schedule tr.inline").each(function(){
        $(this).extend($.extranet.scheduleRow).initialize({});
    });
}

function initEvaluationEvents() {
    // For assignment screen:
    $("input#sendEvaluation").change(function() {
       if($(this).is(":checked")) {
           $("input#evaluationDays").focus();
           $("input#evaluationDays").select();
       }
    });
    $("input#evaluationDays").change(function() {
        var newValue = parseInt($(this).val(), 10);
        if (isNaN(newValue) || newValue < 1) {
            newValue = 1;
        }
        $(this).val(""+newValue);
        updateEvaluationDate(newValue);
    });
    // For evaluation results screen:
    $("span.tip").each(function() {
        var span = $(this);
        var classes = span.attr("class").split(" ");
        $.each(classes, function(index, el) {
            if (el.match(/tip\d+/)) {
                span.hover(function() {
                    showTip(el);
                }, function() {
                    hideTip(el);
                });
            }
        });
    });

    // Contacted checkbox.
    $("input#contacted").change(function() {
       if($(this).is(":checked")) {
           var checkbox = $(this);
           var url = "?";
           $.post(url, {
               o: "evaluation",
               a: "update",
               id: $("input#evaluationId").val(),
               contacted: "true"
           }, function() {
               checkbox.attr("disabled", "disabled");
               location.reload();
           });
       }
    });

    // Contacted reset button:
    $("input#reset_contacted").click(function() {
        var confirmation = confirm("Are you sure you want to reset the evaluation contact information?");
        if (confirmation) {
            var url = "?";
            $.post(url, {
                o: "evaluation",
                a: "update",
                id: $("input#evaluationId").val(),
                reset: "true"
            }, function() {
                location.reload();
            });
        }
    });
}

function updateEvaluationDate(days) {
    if (days == 1) {
        $("span.dayunit").html("day");
    } else {
        $("span.dayunit").html("days");
    }
    var assignmentDateValues = $("input#tmp_date").val().split("-");
    log(assignmentDateValues);
    var assignmentDate = new Date(assignmentDateValues[2], assignmentDateValues[1]-1, assignmentDateValues[0], 0, 0, 0);
    assignmentDate.setDate(assignmentDate.getDate() + days); // Date object fixes overflows of months/years
    var date = $("span.email span.date");
    var newDateValue = zeroPad(assignmentDate.getDate(), 2) + "-" + zeroPad(assignmentDate.getMonth()+1, 2) + "-" + assignmentDate.getFullYear();
    date.html(newDateValue);
}

function zeroPad(value, length) {
    value = value + "";
    while(value.length<length) {
        value = "0"+value;
    }
    return value;
}

$(document).ready(function() {
    // Initialize events on schedule table/form.
    initScheduleEvents();

    // Initialize events on evaluation form.
    initEvaluationEvents();

    $("input#autoscheduletimes").change(function() {
       if ($(this).is(":checked")) {
           $("table.schedule tr.inline:first").trigger("disable");
           enableStartFinishTimes();
       } else {
           $("table.schedule tr.inline:first").trigger("enable");
           disableStartFinishTimes();
       }
    });
});
