var CustomInvoiceController, InvoicingOverview;

InvoicingOverview = class InvoicingOverview {
  constructor(overview1) {
    this.retrieveData = this.retrieveData.bind(this);
    this.attachPersistentEventHandlers = this.attachPersistentEventHandlers.bind(this);
    this.attachEventHandlers = this.attachEventHandlers.bind(this);
    this.toggleInvoicingDetails = this.toggleInvoicingDetails.bind(this);
    this.refreshRow = this.refreshRow.bind(this);
    this.filterRows = this.filterRows.bind(this);
    this.collapseAllRows = this.collapseAllRows.bind(this);
    this.overview = overview1;
    this.attachPersistentEventHandlers();
    this.attachEventHandlers();
    this.openedRows = {};
    this.retrieveData();
    this.currentYear = this.overview.data('year');
    this.currentMonth = this.overview.data('month');
  }

  retrieveData() {
    $('.page-loader').css('display', 'flex');
    this.overview.find(".error-message").hide();
    return $.ajax({
      url: `${location.pathname}.partial${location.search}`,
      method: "GET",
      success: (data) => {
        this.openedRows = {};
        this.overview.find('.contact-rows').html(data);
        return $('.page-loader').css('display', 'none');
      },
      error: (response) => {
        this.overview.find('.contact-rows').html('');
        this.overview.find(".error-message").show();
        return $('.page-loader').css('display', 'none');
      }
    });
  }

  attachPersistentEventHandlers() {
    this.filters = $('.filters .selectize').selectize({
      onChange: () => {
        return this.filterRows();
      }
    });
    return $('.period-selector .selectize').selectize({
      create: false,
      onChange: (value) => {
        var baseHref, customInvoiceButton, updatedHref;
        this.currentYear = value.split('-')[0];
        this.currentMonth = value.split('-')[1];
        history.pushState({}, '', `?year=${this.currentYear}&month=${this.currentMonth}`);
        customInvoiceButton = $("a.new-invoice-button").attr('href');
        if (customInvoiceButton != null) {
          baseHref = customInvoiceButton.split('&month')[0];
          updatedHref = `${baseHref}&month=${this.currentMonth}&year=${this.currentYear}`;
          $("a.new-invoice-button").attr('href', updatedHref); // only available in Receptel
        }
        return this.retrieveData();
      }
    });
  }

  attachEventHandlers() {
    this.overview.on('click focusin', 'input', (e) => {
      return $(e.currentTarget).select();
    });
    this.overview.on('click', '.contact-information', (e) => {
      if (!$(e.target).is('a i')) {
        return this.toggleInvoicingDetails($(e.currentTarget).parent());
      }
    });
    return this.overview.find('a.new-invoice-button').magnificPopup({
      type: 'ajax',
      callbacks: {
        ajaxContentAdded: () => {
          return new InvoicePreview(jQuery('.invoice-preview'), new CustomInvoiceController());
        }
      }
    });
  }

  toggleInvoicingDetails(row) {
    if (row.hasClass('collapsed') && row.hasClass('fetched')) {
      this.collapseAllRows();
      row.removeClass('collapsed');
      return this.openedRows[row.data('id')].scrollToRow();
    } else if (row.hasClass('collapsed') && !row.hasClass('loading')) {
      return this.openedRows[row.data('id')] = new InvoicingOverviewContact(row, this);
    } else {
      return row.addClass('collapsed');
    }
  }

  refreshRow(contact) {
    return $.ajax({
      url: `/admin/finance/invoicing/overview/${contact.row.data('id')}?partial=invoicing_row&year=${this.currentYear}&month=${this.currentMonth}`,
      method: 'GET',
      error: () => {
        return contact.handleError();
      },
      success: (data) => {
        return contact.initializeNewRow(data);
      }
    });
  }

  filterRows() {
    var btFilter, buFilters, statusFilter;
    buFilters = this.filters[0].selectize.getValue().split(',');
    btFilter = this.filters[1].selectize.getValue();
    statusFilter = this.filters[2].selectize.getValue();
    this.overview.find('.contact-container').each((i, row) => {
      if ((buFilters.includes($(row).data('bu').toString())) && ($(row).hasClass(statusFilter.toLowerCase()) || statusFilter === 'All') && ($(row).data('bt') === btFilter || btFilter === 'All')) {
        return $(row).show();
      } else {
        return $(row).hide();
      }
    });
    if (this.overview.find('.contact-container:visible').length > 0) {
      return this.overview.find(".no-results").hide();
    } else {
      return this.overview.find(".no-results").show();
    }
  }

  collapseAllRows() {
    return this.overview.find('.contact-container').addClass('collapsed');
  }

};

CustomInvoiceController = class CustomInvoiceController {
  constructor() {
    this.refresh = this.refresh.bind(this);
  }

  refresh() {
    return jQuery.magnificPopup.close();
  }

};

$(function() {
  var overview;
  overview = jQuery.find(".invoicing-overview");
  if (overview.length > 0) {
    return new InvoicingOverview(jQuery(overview));
  }
});
