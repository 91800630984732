this.months_between = function(start, end) {
  var months;
  months = (end.getFullYear() - start.getFullYear()) * 12 + end.getMonth() - start.getMonth();
  if (start.getDate() === 1) {
    months += 1;
  }
  return months;
};

$(function() {
  return $("input.datepicker").siblings("a.clear").click(function(e) {
    $(this).siblings("input.datepicker").val("");
    return e.preventDefault();
  });
});
