// highlight current page in sidebar
$(function() {
  var i, len, link, ref, results, sidebar;
  sidebar = jQuery.find('td.sidemenu');
  if (!sidebar.length) {
    sidebar = jQuery.find('.rails-sidemenu');
  }
  ref = jQuery(sidebar).find('a');
  results = [];
  for (i = 0, len = ref.length; i < len; i++) {
    link = ref[i];
    if (window.location.href.startsWith(link.href)) {
      results.push(jQuery(link).parent().addClass('active'));
    } else {
      results.push(void 0);
    }
  }
  return results;
});
