var request;
var queryString;
var timeOutId;
var timeOut = false;
var ajax_reciever;
var ajax_callback;

window.sendRec = function sendRec(query, object, action, reciever, callback) {
    if (reciever == '') {
        reciever = action;
    }
    if (!document.getElementById(reciever)) {
        reciever = object + '_' + action + '_results';
    }
    if (!document.getElementById(reciever)) {
        reciever = object + '_' + action;
    }
    if (!document.getElementById(reciever)) {
        alert('No reciever (' + reciever + ', ' + object + ', ' + action + ')');
        return false;
    }

    queryString = 'o=ajax_' + object + '&a=' + action + '&' + query + '&ajax_forced=true';
    var url = "?" + queryString;

    httpRequest("POST", url, true);
    ajax_reciever = reciever;
    ajax_callback = callback;
    document.getElementById(ajax_reciever).innerHTML = '<img src="/legacy_images/admin/ajax-loader.gif" alt="loader" align="absmiddle" />&nbsp;Busy...';
}

window.ajax_search_forced = function ajax_search_forced(value, object, action, extra){
    if(timeOut) {
        window.clearTimeout(timeOutId);
        timeOut = false;
    }
    sendRec('s=' + value + '&' + extra, object, action);
}

window.ajax_search = function ajax_search(value, object, action, extra){
    if(timeOut) {
        window.clearTimeout(timeOutId);
        timeOut = false;
    }
    if(value.length >= 3){
        timeOutId = setTimeout("sendRec('s="+value+"&"+extra+"', '"+object+"', '"+action+"');", 800);
        timeOut = true;
    }
}

window.sendForm = function sendForm(form, fields, object, action, receiver, callback) {

    form = eval('document.'+form);
    query='';
    for(i=0; i<form.elements.length; i++){
        contained = false;
        for(j=0;j<fields.length;j++){
            if(fields[j] == form.elements[i].name){
                contained = true;
                continue;
            }
        }
        if(contained) query+= '&'+form.elements[i].name+'='+form.elements[i].value;
    }
    sendRec(query, object, action, receiver, callback);
}

// Same functionality as "sendForm" above, but handling nested form fields
window.sendEmbeddedForm = function sendEmbeddedForm(container, fields, object, action, reciever, callback) {
    var query='';
    $("#"+container).find("input,textarea").each(function() {
        var name = $(this).attr("name");
        var value = $(this).val();
        if ($.inArray(name, fields) > -1) {
            query+= '&' + encodeURIComponent(name) + '=' + encodeURIComponent(value);
        }
    })
    sendRec(query, object, action, reciever, callback);
}

window.handleResponse = function handleResponse() {
    if  (request.readyState == 4) {
        if (request.status == 200){
            document.getElementById(ajax_reciever).innerHTML = request.responseText;
            if (ajax_callback) {
                ajax_callback();
            }
        } else {
            alert("A problem occurred with communicating between " +
                  "the XMLHttpRequest object and the server program.");
        }
    }
}

/* Initialize a request object that is already constructed */
function initReq(reqType,url,bool){
    /* Specify the function that will handle the HTTP response */
    request.onreadystatechange=handleResponse;
    request.open(reqType,url,bool);
    request.setRequestHeader("Content-Type",
            "application/x-www-form-urlencoded; charset=UTF-8?");
    /* Only works in Mozilla-based browsers */
    //request.overrideMimeType("text/xml");
    request.send(queryString);
    //prompt('',url+'?'+queryString);
}

/* Wrapper function for constructing a request object.
 Parameters:
  reqType: The HTTP request type, such as GET or POST.
  url: The URL of the server program.
  asynch: Whether to send the request asynchronously or not. */
function httpRequest(reqType,url,asynch){
    //Mozilla-based browsers
    if(window.XMLHttpRequest){
        request = new XMLHttpRequest(  );
    } else if (window.ActiveXObject){
        // request=new ActiveXObject(Msxml2.XMLHTTP);
        //  if (! request){
        //      request=new ActiveXObject(Microsoft.XMLHTTP);
        //  }
        try {
            request=new ActiveXObject('Msxml2.XMLHTTP');
        } catch (e) {
            try {
                request=new ActiveXObject('Microsoft.XMLHTTP');
            } catch (oc) {
                request=null;
            }
        }
    }


    //the request could still be null if neither ActiveXObject
    //initialization succeeded
    if(request){
        initReq(reqType,url,asynch);
    } else {
        alert("Your browser does not permit the use of all" +
              "of this applications features!");
    }
}

function setQueryString(  ){
    queryString="";
    var frm = document.forms[0];
    var numberElements =  frm.elements.length;
    for(var i = 0; i < numberElements; i++) {
        if(i < numberElements-1) {
            queryString += frm.elements[i].name+"="+
                           encodeURIComponent(frm.elements[i].value)+"&";
        } else {
            queryString += frm.elements[i].name+"="+
                           encodeURIComponent(frm.elements[i].value);
        }
    }
}

/* Provide the div elements content dynamically. We can add
style information to this function if we want to jazz up the div */
function stylizeDiv(bdyTxt,div){
    //reset DIV content
    div.innerHTML="";
    div.style.backgroundColor="yellow";
    div.innerHTML=bdyTxt;
}

/* Get information about an XML document via a DOM Document object */
function getDocInfo(doc){
    var root = doc.documentElement;
    var info = "<h3>Document root element name: <h3 />"+ root.nodeName;
    var nds;
    if(root.hasChildNodes(  )) {
        nds=root.childNodes;
        info+= "<h4>Root nodes child node names/values:<h4/>";
        for (var i = 0; i < nds.length; i++){
            if(nds[i].hasChildNodes(  )){
                info+=  nds[i].nodeName;
                info+=  " : "+nds[i].firstChild.nodeValue+' ';
                if(nds[i].hasChildNodes(  )){
                    tmpnds = nds[i].childNodes;
                    info+='{';
                    for(var j=0; j<tmpnds.length;j++){
                        if(tmpnds[j].hasChildNodes(  )){
                            info+= tmpnds[j].nodeName+' : '+tmpnds[j].firstChild.nodeValue+' ';
                        }
                    }
                    info+='}';
                }
                info+="<br />";
            } else if(nds[i].nodeName == '#text' && nds[i].nodeValue.replace(/(\n|\r| )+$/, '') != '') {
                info+=  nds[i].nodeName+' : '+nds[i].nodeValue+"<br />";
            }else if(nds[i].nodeName != '#text'){
                info+=  nds[i].nodeName+" : Empty<br />";
            }
        }
    }
    //alert(root.childNodes[3].childNodes[2].firstChild.nodeValue );
    return info;
}
