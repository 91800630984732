// Using lint here causes an error with IE11. I aint getting on that train no sir.
// prettier-ignore
function savedLocation() {
  var fieldPrefix = $(this).parent().parent().parent().parent().parent().attr('id');
  if ($(this).val() == "") {
    $("input#" + fieldPrefix + "_save").parent().parent().show();
    var address = { 'name': '', 'address': '', 'postal_code': '', 'city': '' };
  } else {
    $("input#" + fieldPrefix + "_save").attr('checked', false);
    $("input#" + fieldPrefix + "_save").parent().parent().hide();
    var address = addresses[$(this).val()];
  }

  for (fieldName in address) {
    var qry = "input#" + fieldPrefix + "_" + fieldName;
    $(qry).val(address[fieldName]);
  }
}

// prettier-ignore
function addLocationHandlers() {
  $("select.savedlocations").change(savedLocation);
  $("a#removeLocation").click(function () {
    $(this).parent().parent().parent().parent().remove();
    return false;
  });
}

// prettier-ignore
$(document).ready(function () {
  var currentLocation = 0;
  $("a#addLocation").click(function () {
    currentLocation += 1;
    var url = '?o=newassignment&a=location_form&fieldid=' + currentLocation;
    $.get(url, {}, function (data) {
      // Set the contents of the new location form
      $('div.scheduler').append(data);

      // Take care of arrival/departure time rows
      $("tr#departureTime" + (currentLocation - 1)).css("visibility", "visible");

      // Add some handlers
      addLocationHandlers();
    });
    return false;
  });
  $("select.savedlocations").change(savedLocation);
});

// prettier-ignore
$(document).ready(function () {
  $("div.monthview a.assignment").hover(function () {
    var element = $("#tooltip" + this.id);
    element.show();
    element.css("visibility", "visible");
    var posleft = $(this).offset().left + 10;
    var postop = $(this).offset().top + 20;
    element.css({ top: postop, left: posleft });
  }, function () {
    var element = $("#tooltip" + this.id);
    element.hide();
  });
});
