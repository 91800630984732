var KnowledgeBase, initTinyMce;

require('tinymce/themes/modern');

require('tinymce/plugins/link');

require('tinymce/plugins/paste');

require('tinymce/plugins/table');

KnowledgeBase = class KnowledgeBase {
  constructor(element) {
    this.element = element;
    this.questionFormSection = this.element.find(".question-form-block");
    this.newCategoryButton = this.element.find(".question-form-block .new-category-button");
    this.backButton = this.element.find(".question-form-block .existing-category-button");
    this.newCategorySection = this.element.find(".question-form-block .new-category");
    this.existingCategorySection = this.element.find(".question-form-block .existing-category");
    this.alertBox = this.element.find(".question-form-block .alert");
    this.submitButton = this.element.find(".question-form-block .submit");
    //index page specific
    this.newButton = this.element.find(".new-question-button");
    this.closeNewButton = this.element.find(".new-question .close-new-question-button");
    this.initInputs();
    this.attachHandlers();
  }

  attachHandlers() {
    this.newButton.on("click", (event) => {
      return this.questionFormSection.slideDown();
    });
    this.closeNewButton.on("click", (event) => {
      return this.questionFormSection.slideUp();
    });
    this.newCategoryButton.on("click", (event) => {
      this.newCategorySection.show();
      return this.existingCategorySection.hide();
    });
    this.backButton.on("click", (event) => {
      this.existingCategorySection.show();
      return this.newCategorySection.hide();
    });
    this.submitButton.on("click", (event) => {
      if (this.validate()) {
        return this.element.find(".question-form-block form").submit();
      }
    });
    return $(document).on("click", ".delete-category", (event) => {
      var category_name, form;
      form = jQuery(event.target).parents("form")[0];
      category_name = jQuery(event.target).parents(".name").text().trim();
      if (confirm("Are you sure you want to delete category: '" + category_name + "'?")) {
        if (jQuery(event.target).data()["doubleConfirm"]) {
          if (confirm("Deleting a category will also delete all question and categories below it. Are you sure?")) {
            return form.submit();
          }
        } else {
          return form.submit();
        }
      }
    });
  }

  initInputs() {
    this.filters = this.element.find("select.selectize");
    return this.filters.each((index, field) => {
      return jQuery(field).selectize({
        allowEmptyOption: true,
        create: false
      });
    });
  }

  validate() {
    var valid;
    valid = true;
    if (this.questionFormSection.find("#knowledge_base_question_question").val() === "") {
      this.questionFormSection.find("#knowledge_base_question_question").addClass("invalid");
      valid = false;
    }
    if (tinyMCE.activeEditor && tinyMCE.activeEditor.getContent() === "") {
      jQuery(tinyMCE.activeEditor.getContainer()).addClass("invalid");
      valid = false;
    }
    if (this.newCategorySection.is(":visible") && this.questionFormSection.find("#knowledge_base_question_category_attributes_name").val() === "") {
      this.questionFormSection.find("#knowledge_base_question_category_attributes_name").addClass("invalid");
      valid = false;
    }
    if (!valid) {
      this.alertBox.html("Fields with a red border are required.");
      this.alertBox.show();
    }
    return valid;
  }

};

initTinyMce = function() {
  return tinyMCE.init({
    selector: "textarea.tinymce",
    menu: "core",
    toolbar: "bold italic bullist numlist | link unlink | table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol | removeformat",
    plugins: "link table paste",
    width: 800,
    height: 300,
    paste_as_text: true
  });
};

jQuery(function() {
  if (jQuery(".knowledge-base-admin").length > 0) {
    new KnowledgeBase(jQuery(".knowledge-base-admin"));
    return initTinyMce();
  }
});
