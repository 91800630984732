var locationAutocomplete;

window.locationAutocomplete = locationAutocomplete = class locationAutocomplete {
  constructor(el) {
    this.autofillForm = this.autofillForm.bind(this);
    this.setLocation = this.setLocation.bind(this);
    this.el = el;
    this.autofillForm();
  }

  autofillForm() {
    this.autocomplete = new google.maps.places.Autocomplete(this.el[0]);
    this.autocomplete.setComponentRestrictions({
      'country': ['nl', 'be', 'fr', 'de', 'lu']
    });
    return this.autocomplete.addListener('place_changed', this.setLocation);
  }

  setLocation() {
    var place;
    place = this.autocomplete.getPlace();
    return $.ajax({
      url: '/admin/api/locations',
      method: 'POST',
      data: {
        location: place.address_components,
        name: place.name,
        place: place.place_id
      },
      success: (response) => {
        this.el.next().val(response.locationid);
        return this.el.closest('.field-group').find('span.location-name').html(response.name);
      }
    });
  }

};

window.initAutocomplete = function() {
  return jQuery(".autocomplete").each((index, element) => {
    return new locationAutocomplete(jQuery(element));
  });
};
