var InvoicePreview;

window.InvoicePreview = InvoicePreview = class InvoicePreview {
  constructor($el, $source) {
    this.refreshInvoice = this.refreshInvoice.bind(this);
    this.$el = $el;
    this.$source = $source;
    this.initializeFields();
    this.attachHandlers();
  }

  refreshInvoice(src) {
    jQuery("iframe").attr("src", src);
    // Enable this if we want to prevent 0 euro invoices from being submitted.
    // if $(".fields input.invoice_line_amount").length
    //   total_amount = $(".fields input.invoice_line_amount").map((_, amount) => $(amount).val()).get().reduce((a, b) => (a || 0) + parseFloat(b))
    // else
    //   total_amount = 0
    return $(".submit-custom-invoice").prop('disabled', !$(".selectize-contact").val());
  }

  initializeFields() {
    this.$el.find(".datepicker").datepicker();
    this.$el.find("select#business_unitid.selectize").selectize({
      onChange: (value) => {
        var src;
        src = jQuery("iframe").attr("src").replace(/business_unitid=\d/, "business_unitid=" + value);
        return this.refreshInvoice(src);
      }
    });
    this.$el.find("select#period_type.selectize").selectize({
      onChange: (value) => {
        var src;
        src = jQuery("iframe").attr("src").replace("period_type=month", "period_type=" + value).replace("period_type=year", "period_type=" + value);
        return this.refreshInvoice(src);
      }
    });
    this.$el.find("select.read-only-selectize").selectize({
      onChange: (value) => {
        var src;
        src = jQuery("iframe").attr("src").replace("period_type=month", "period_type=" + value).replace("period_type=year", "period_type=" + value);
        return this.refreshInvoice(src);
      }
    });
    return this.$el.find("select.selectize-contact").selectize({
      allowEmptyOption: false,
      create: false,
      searchField: ['contact', 'company'],
      maxOptions: 100,
      render: {
        option: function(item, escape) {
          if (!item.contact) {
            return "<div class='select-contact-option empty'></div>";
          } else {
            return `<div class='select-contact-option'> ${escape(item.contact)} <div class='company'> ${(item.company ? `${escape(item.company)}` : void 0) || ''} </div> <div class='bu'> ${escape(item.bu)} </div> </div>`;
          }
        }
      }
    });
  }

  attachHandlers() {
    $('#invoice_lines').on("nested:fieldAdded", (e) => {
      $(e.target).find('.selectize').selectize({
        allowEmptyOption: false,
        search: false
      });
      $(e.target).find("select.selectize-category").selectize({
        allowEmptyOption: false,
        create: false,
        search: false,
        maxOptions: 100,
        render: {
          option: function(item, escape) {
            return `<div class='selectize-category-option'> ${escape(item.name)} <div class='description'> ${escape(item.description)} </div> </div>`;
          }
        }
      });
      return this.setInvoiceLineParams();
    });
    $('#invoice_lines').on("nested:fieldRemoved", (e) => {
      $(e.target).removeClass('fields');
      return this.setInvoiceLineParams();
    });
    jQuery('#create-invoice').on('submit', (e) => {
      this.$el.find(".exception-message").css('display', 'none');
      return this.$el.find(".invoice-preview-container").addClass('loading');
    });
    jQuery('#create-invoice').bind('ajax:success', (event, data) => {
      return this.$source.refresh();
    });
    jQuery('#create-invoice').bind('ajax:error', (event, data) => {
      this.$el.find(".exception-message").css('display', 'inline-block');
      return this.$el.find(".invoice-preview-container").removeClass('loading');
    });
    jQuery(".selectize-contact").on("change", (e) => {
      var bu_id, src;
      if ($(e.target).val()) {
        jQuery(".new-invoice-company-name").html(`for ${$(e.target).data().selectize.options[$(e.target).val()].company}`);
        bu_id = $(e.target).data().selectize.options[$(e.target).val()].bu_id;
        jQuery("select#business_unitid").data().selectize.setValue(bu_id);
        src = jQuery("iframe").attr("src").replace(/contact_id=(.*?)&/, "contact_id=" + $(e.target).val() + "&");
        src = src.replace(/business_unitid=(.*?)&/, "business_unitid=" + bu_id + "&");
      } else {
        jQuery(".new-invoice-company-name").html("");
        src = jQuery("iframe").attr("src").replace(/contact_id=(.*?)&/, "contact_id=0&");
      }
      return this.refreshInvoice(src);
    });
    jQuery("#invoice_lines").on("change", ".invoice_line_category", (e) => {
      return $(e.target).closest(".fields").find(".invoice_line_vat").data().selectize.setValue($(e.target).data().selectize.options[$(e.target).val()].vat);
    });
    jQuery("#invoice_lines").on("change", ".invoice_line_amount, .invoice_line_vat, .invoice_line_category", (e) => {
      return this.setInvoiceLineParams();
    });
    jQuery("input[name='reference']").on("change", (event) => {
      var src;
      if (jQuery("iframe").attr("src").includes("reference=")) {
        src = jQuery("iframe").attr("src").replace(/reference=(.*?)&/, "reference=" + jQuery(event.target).val() + "&");
      } else {
        src = jQuery("iframe").attr("src") + "&reference=" + jQuery(event.target).val() + "&";
      }
      return this.refreshInvoice(src);
    });
    jQuery("#show_employees").on("change", (event) => {
      var src;
      src = jQuery("iframe").attr("src").replace(/show_employees=\d/, "show_employees=" + (jQuery(event.target).is(":checked") ? "1" : "0"));
      return this.refreshInvoice(src);
    });
    jQuery("#show_itinerary").on("change", (event) => {
      var src;
      src = jQuery("iframe").attr("src").replace(/show_itinerary=\d/, "show_itinerary=" + (jQuery(event.target).is(":checked") ? "1" : "0"));
      return this.refreshInvoice(src);
    });
    jQuery("#show_overtime_charge").on("change", (event) => {
      var src;
      src = jQuery("iframe").attr("src").replace(/show_overtime_charge=\d/, "show_overtime_charge=" + (jQuery(event.target).is(":checked") ? "1" : "0"));
      return this.refreshInvoice(src);
    });
    return jQuery("a[data-action='cancel']").on("click", function(event) {
      return jQuery.magnificPopup.close();
    });
  }

  setInvoiceLineParams() {
    var invoice_line_data, src;
    src = jQuery("iframe").attr("src").split("&invoice_lines_attributes[]")[0];
    invoice_line_data = jQuery("#invoice_lines .fields").map((index, invoice_line) => {
      var category, rate, vat;
      category = $(invoice_line).find("select.invoice_line_category").val();
      rate = $(invoice_line).find("input.invoice_line_amount").val();
      vat = $(invoice_line).find("select.invoice_line_vat").val();
      return `&invoice_lines_attributes[][quantity]=1&invoice_lines_attributes[][custom_category_id]=${category}&invoice_lines_attributes[][rate]=${rate}&invoice_lines_attributes[][vat]=${vat}`;
    });
    return this.refreshInvoice(src + invoice_line_data.get().join(""));
  }

};
