$(function() {
  jQuery(".vacations .schedule td").on("click", function(event) {
    var column;
    column = jQuery(event.target).prevAll("td").length + 1;
    return jQuery(`.vacations .schedule td:nth-child(${column})`).toggleClass("highlight");
  });
  if (jQuery('.business-units-dropdown').length) {
    jQuery(window).on("click", function(event) {
      if (jQuery('.business-units-dropdown').hasClass('open')) {
        if (!(jQuery(event.target).hasClass('business-units-content') || jQuery(event.target).parents('.business-units-content').length)) {
          return jQuery('.business-units-dropdown').removeClass('open');
        }
      } else if (jQuery(event.target).hasClass('business-units-dropdown') || jQuery(event.target).parents('.business-units-dropdown').length) {
        return jQuery('.business-units-dropdown').addClass('open');
      }
    });
    return jQuery('.toggle-all-business-units').on('click', function(event) {
      return jQuery(event.target).closest('form').find('input:checkbox').each((index, checkbox) => {
        return jQuery(checkbox).prop('checked', !jQuery(checkbox).prop('checked'));
      });
    });
  }
});
