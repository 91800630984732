var Contract;

Contract = (function() {
  var formatDate, parseDate;

  class Contract {
    constructor(el) {
      this.attachRateEvents = this.attachRateEvents.bind(this);
      this.attachDiscountEvents = this.attachDiscountEvents.bind(this);
      this.attachEvents = this.attachEvents.bind(this);
      this.fetchDynamicData = this.fetchDynamicData.bind(this);
      this.el = el;
      // These two variables are the reason I duplicated most code for the new discount block,
      // instead of generalizing it.
      this.adjustingRate = null;
      this.adjustingDiscount = null;
      this.contractEndDate = $('#contract_end_date').val();
      this.companyId = $('#contract_company_id').val();
      this.venueId = $('#contract_venue_id').val();
      this.contactId = $('#contract_contact_id').val();
      this.businessUnitId = $('#contract_business_unit_id').val();
      this.attachEvents();
    }

    attachRateEvents(row) {
      row.find('.adjust-rate').on('click', (e) => {
        this.adjustingRate = $(e.target).closest('tr.fields');
        return $('.add-service').trigger('click');
      });
      row.find('.rate-validity').on('change', (e) => {
        var date;
        row = $(e.target).closest('tr');
        date = parseDate($(e.currentTarget).val());
        if (row.attr('data-parent') && $(e.currentTarget).hasClass('valid-from')) {
          date.setDate(date.getDate() - 1);
          return $(`#${row.attr('data-parent')}`).val(formatDate(date));
        } else if (row.attr('data-child') && $(e.currentTarget).hasClass('valid-until')) {
          date.setDate(date.getDate() + 1);
          return $(`#${row.attr('data-child')}`).val(formatDate(date));
        }
      });
      row.find("input.datepicker").datepicker();
      return row.find('.selectize').selectize();
    }

    attachDiscountEvents(row) {
      row.find('.adjust-rate').on('click', (e) => {
        this.adjustingDiscount = $(e.target).closest('tr.fields');
        return $('.add-job-title-discount').trigger('click');
      });
      row.find('.rate-validity').on('change', (e) => {
        var date;
        row = $(e.target).closest('tr');
        date = parseDate($(e.currentTarget).val());
        if (row.attr('data-parent') && $(e.currentTarget).hasClass('valid-from')) {
          date.setDate(date.getDate() - 1);
          return $(`#${row.attr('data-parent')}`).val(formatDate(date));
        } else if (row.attr('data-child') && $(e.currentTarget).hasClass('valid-until')) {
          date.setDate(date.getDate() + 1);
          return $(`#${row.attr('data-child')}`).val(formatDate(date));
        }
      });
      row.find("input.datepicker").datepicker();
      return row.find('.selectize').selectize();
    }

    attachEvents() {
      this.el.on('click', '.invoices-button', (e) => {
        return jQuery.magnificPopup.open({
          items: {
            src: "#invoices-container"
          }
        });
      });
      this.el.on('click', '.auto-invoice-toggle', (e) => {
        if ($('#invoiceable').is(':checked')) {
          return $('.automatic-invoicing-options').addClass('hidden');
        } else if ($('#contract_contact_id').val() !== '') {
          return $('.automatic-invoicing-options').removeClass('hidden');
        }
      });
      $('#custom-rates tr.fields').each((index, element) => {
        return this.attachRateEvents($(element));
      });
      $('#job-title-discounts tr.fields').each((index, element) => {
        return this.attachDiscountEvents($(element));
      });
      $('#contract_end_date').on('change', (e) => {
        $('.rate-validity.valid-until').each((index, element) => {
          if (this.contractEndDate === $(element).val()) {
            return $(element).val($(e.target).val());
          }
        });
        return this.contractEndDate = $(e.target).val();
      });
      $('#services').on("nested:fieldAdded", (e) => {
        return $(e.target).find('.selectize').selectize();
      });
      $('#custom-rates').on("nested:fieldAdded:custom_rates", (e) => {
        var row;
        row = $(e.target);
        if (this.adjustingRate) {
          row.insertAfter(this.adjustingRate);
          row.find('.service-type select').val(this.adjustingRate.find('.service-type select').val()).trigger('change');
          row.find('.service-type').addClass('hidden');
          row.find('.valid-from').val('');
          row.attr('data-parent', this.adjustingRate.find('.valid-until').attr('id'));
          this.adjustingRate.attr('data-child', row.find('.valid-from').attr('id'));
          this.adjustingRate.find('.valid-until').val('');
          this.adjustingRate.find('.adjust-button').addClass('hidden');
          this.adjustingRate = null;
        }
        return this.attachRateEvents(row);
      });
      $('#custom-rates').on("nested:fieldRemoved:custom_rates", (e) => {
        $(e.target).find('input').removeAttr('required');
        // TODO: Make this more robust
        return $(`#${$(e.target).attr('data-parent')}`).closest('tr').find('.adjust-button').removeClass('hidden');
      });
      $('#job-title-discounts').on("nested:fieldAdded:job_title_discounts", (e) => {
        var row;
        row = $(e.target);
        if (this.adjustingDiscount) {
          row.insertAfter(this.adjustingDiscount);
          row.find('.job-title select').val(this.adjustingDiscount.find('.job-title select').val()).trigger('change');
          row.find('.job-title').addClass('hidden');
          row.find('.valid-from').val('');
          row.attr('data-parent', this.adjustingDiscount.find('.valid-until').attr('id'));
          this.adjustingDiscount.attr('data-child', row.find('.valid-from').attr('id'));
          this.adjustingDiscount.find('.valid-until').val('');
          this.adjustingDiscount.find('.adjust-button').addClass('hidden');
          this.adjustingDiscount = null;
        }
        return this.attachDiscountEvents(row);
      });
      $('#job-title-discounts').on("nested:fieldRemoved:job_title_discounts", (e) => {
        $(e.target).find('input').removeAttr('required');
        // TODO: Make this more robust
        return $(`#${$(e.target).attr('data-parent')}`).closest('tr').find('.adjust-button').removeClass('hidden');
      });
      $('.main-details .selectize').selectize();
      $("#contract_end_date").datepicker({
        minDate: $("#contract_start_date").val(),
        maxDate: null,
        numberOfMonths: 3,
        changeMonth: true,
        changeYear: true,
        beforeShowDay: function(date) {
          var start_date;
          start_date = parseDate($("#contract_start_date").val());
          date.setHours(0, 0, 0, 0);
          // Disable dates that are earlier than the start_date.
          if (date < start_date) {
            return [false, "", ""];
          } else {
            return [true, "", ""];
          }
        }
      });
      this.el.find("input.datepicker").datepicker();
      this.el.find("select.selectize-company").selectize({
        allowEmptyOption: false,
        create: false,
        searchField: ['company', 'address'],
        onChange: (value) => {
          this.companyId = value;
          this.venueId = '';
          this.contactId = '';
          return this.fetchDynamicData(this.companyId, this.venueId, this.contactId, this.businessUnitId);
        },
        render: {
          option: function(item, escape) {
            return `<div class='select-company-option'> ${escape(item.company)} <div class='address'> ${(item.address ? `${escape(item.address)}` : void 0) || ''} </div> </div>`;
          }
        }
      });
      this.el.find("select.selectize-venue").selectize({
        allowEmptyOption: true,
        create: false,
        searchField: ['venue'],
        onChange: (value) => {
          this.venueId = value;
          return this.fetchDynamicData(this.companyId, this.venueId, this.contactId, this.businessUnitId);
        }
      });
      this.el.find("select.selectize-contact").selectize({
        allowEmptyOption: true,
        create: false,
        searchField: ['contact'],
        onChange: (value) => {
          this.contactId = value;
          return this.fetchDynamicData(this.companyId, this.venueId, this.contactId, this.businessUnitId);
        }
      });
      this.el.find("select.selectize-bu").selectize({
        allowEmptyOption: false,
        create: false,
        searchField: ['business_unit'],
        onChange: (value) => {
          this.businessUnitId = value;
          return this.fetchDynamicData(this.companyId, this.venueId, this.contactId, this.businessUnitId);
        }
      });
      return this.el.find("select.selectize-account").selectize({
        allowEmptyOption: false,
        create: false,
        searchField: ['account', 'user'],
        maxOptions: 100
      });
    }

    fetchDynamicData(companyId, venueId, contactId, businessUnitId) {
      if (!(companyId.length > 0 || contactId.length > 0 || businessUnitId.length > 0)) {
        return;
      }
      return $.ajax({
        url: `${window.location.pathname}.js?company=${companyId}&venue=${venueId}&contact=${contactId}&business_unit=${businessUnitId}`,
        method: 'GET',
        success: (data) => {
          return this.attachEvents();
        }
      });
    }

  };

  parseDate = function(string) {
    var split;
    split = string.split("-");
    return new Date(split[2], split[1] - 1, split[0]);
  };

  formatDate = function(date) {
    return `${("0" + date.getDate()).slice(-2)}-${("0" + (date.getMonth() + 1)).slice(-2)}-${date.getFullYear()}`;
  };

  return Contract;

}).call(this);

$(function() {
  var contract;
  contract = jQuery.find('#contract');
  if (contract.length > 0) {
    return new Contract(jQuery(contract));
  }
});
