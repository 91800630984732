// Converted from hamlc, if you need to make significant changes to it consider creating something new instead
var ScheduleController, _, absence_template, assignment_template, availability_template;

_ = require('underscore');

assignment_template = "<section style='border-bottom: 4px solid <%= entity.color %>'> <h3> Opdracht details <% if (entity.pdf === 'show') { %> <a class='open-pdf' target='_blank' href='/employees/index.php?o=assignments&a=pdf&assignmentid=<%= entity.id %>&version=<%= entity.version %>'> <i class='fas fa-file-pdf' /> </a> <% } %> </h3> <table> <tr><th>Opdrachtgever</th><td><%= entity.company || '-' %></td></tr> <tr><th>Contactpersoon</th><td><%= entity.contact || '-' %></td></tr> <tr><th>Locatie</th><td><%= entity.location || '-' %></td></tr> <tr><th>Locatie notities</th><td><%= entity.locationNotes || '-' %></td></tr> <tr><th>Opdrachtnummer</th><td><%= entity.id %></td></tr> <tr class='divider'><th /><td /></tr> <tr><th>Medewerker</th><td><%= entity.employee || '-' %></td></tr> <tr><th>Dienst</th><td><%= entity.serviceType || '-' %></td></tr> <tr><th>Datum</th><td><%= entity.date || '-' %></td></tr> <tr><th>Begintijd</th><td><%= entity.start %></td></tr> <tr><th>Pauzetijd</th><td><%= entity.break || '-' %></td></tr> <tr><th>Eindtijd</th><td><%= entity.end %></td></tr> <tr><th>Notities</th><td><%= entity.assignmentNotes || '-' %></td></tr> </table> </section>";

availability_template = "<section style='border-bottom: 4px solid green'> <h3> Beschikbaarheid details </h3> <table> <tr><th>Begintijd</th><td><%= entity.from %></td></tr> <tr><th>Eindtijd</th><td><%= entity.to %></td></tr> </table> </section>";

absence_template = "<section style='border-bottom: 4px solid grey'> <h3> Afwezigheid details </h3> <table> <tr><th>Type</th><td><%= entity.type %></td></tr> <tr><th>Begintijd</th><td><%= entity.from %></td></tr> <tr><th>Eindtijd</th><td><%= entity.to %></td></tr> <tr><th>Notities</th><td><%= entity.comment || '-' %></td></tr> </table> </section>";

ScheduleController = class ScheduleController {
  constructor($container) {
    this.attachEventHandlers = this.attachEventHandlers.bind(this);
    this.$container = $container;
    this.filters = $(this.$container.find('.filters')[0]);
    this.attachEventHandlers();
  }

  attachEventHandlers() {
    this.$container.on('change', '.filters select', (e) => {
      var period, sequence, year;
      [year, sequence, period] = $(e.target).val().split("-");
      return location.href = `?period=${period}&sequence=${sequence}&year=${year}`;
    });
    this.$container.on('change', '.employee-filter select', (e) => {
      if ($(e.target).val()) {
        return location.href = `/admin/operations/employee_plannings/${$(e.target).val()}${location.search}`;
      }
    });
    return this.$container.on('click', '.planning', function(e) {
      var tooltip_template;
      if ($(e.target).closest('.planning').hasClass('availability')) {
        tooltip_template = _.template(availability_template);
      } else if ($(e.target).closest('.planning').hasClass('absence')) {
        tooltip_template = _.template(absence_template);
      } else {
        tooltip_template = _.template(assignment_template);
      }
      $('#schedule-popup .content').html(tooltip_template({
        entity: $(e.target).closest('.planning').data()
      }));
      return jQuery.magnificPopup.open({
        items: {
          src: "#schedule-popup"
        }
      });
    });
  }

};

$(window).load(function() {
  if ($('.portal.schedules, .portal.time-sheets').length || $('.admin-employee-view').length) {
    return new ScheduleController($('main section'));
  }
});
