$(function() {
  return $.get("/admin/api/notices.json", function(data) {
    var i, len, notice;
    for (i = 0, len = data.length; i < len; i++) {
      notice = data[i];
      if (notice.count > 0) {
        $(".sidemenu a[data-badge-id='" + notice.badge_id + "'], .rails-sidemenu a[data-badge-id='" + notice.badge_id + "']").each(function(index, element) {
          return $(element).append("<span class=\"badge\">" + notice.count + "</span>");
        });
      }
    }
    return $("header a[data-badge-id]").each(function(index, element) {
      var j, len1, section, total;
      section = $(element).attr("data-badge-id");
      total = 0;
      for (j = 0, len1 = data.length; j < len1; j++) {
        notice = data[j];
        if (notice.section === section) {
          total += notice.count;
        }
      }
      if (total > 0) {
        return $(element).append("<span class=\"badge\">" + total + "</span>");
      }
    });
  });
});
