$(function() {
  var dashboard;
  dashboard = $('#employee_dashboard_search_box');
  if (dashboard.length > 0 && window.location.href.indexOf('employeeid') === -1) {
    return ajax_search_forced('', 'employee_dashboard', 'search', '&show_av=1&show_ua=0&show_ue=0&ajax_forced=true');
  } else {
    return $('#copy-email-signature').on('click', function(e) {
      var range;
      $('#email-signature').show();
      range = document.createRange();
      range.selectNode(document.getElementById('email-signature-inner'));
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);
      document.execCommand('copy');
      return $('#email-signature').hide();
    });
  }
});
