var UnassignedController;

UnassignedController = class UnassignedController {
  constructor($container) {
    this.attachEventHandlers = this.attachEventHandlers.bind(this);
    this.nextPeriod = this.nextPeriod.bind(this);
    this.fetchSchedule = this.fetchSchedule.bind(this);
    this.$container = $container;
    this.filters = $(this.$container.find('.filters')[0]);
    this.attachEventHandlers();
  }

  attachEventHandlers() {
    this.$container.on('click', '.toggle', (e) => {
      $('.toggle-option').toggleClass('selected');
      $('.toggle-target').toggleClass('selected');
      return this.filters.attr('data-type', $('.toggle-option.selected').data('type'));
    });
    this.$container.on('change', '.filters select', (e) => {
      return this.fetchSchedule($(e.target).val(), $(e.target).attr('id'), 0);
    });
    this.$container.on('click', '.arrow.previous', (e) => {
      return this.nextPeriod(-1);
    });
    return this.$container.on('click', '.arrow.next', (e) => {
      return this.nextPeriod(1);
    });
  }

  reInitTooltip() {
    if (jQuery('.tooltip-trigger').length) {
      return new TooltipController(jQuery('.tooltip-trigger'));
    }
  }

  nextPeriod(offset) {
    return this.fetchSchedule(this.filters.data('date'), offset);
  }

  fetchSchedule(date, offset) {
    if (this.filters.find('.filter').hasClass('loading')) {
      return;
    }
    this.filters.find('.filter').addClass('loading');
    return jQuery.ajax({
      url: `/admin/operations/unassigned_plannings/${date}.js?&offset=${offset}`,
      method: "GET",
      success: () => {
        this.filters = $(this.$container.find('.filters')[0]);
        return this.reInitTooltip();
      }
    });
  }

};

$(function() {
  if ($('section .unassigned-planning').length) {
    return new UnassignedController($('section .unassigned-planning'));
  }
});
