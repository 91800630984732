/*
jQuery Placeholder plugin
Copyright 2010, Daniel Stocks (http://webcloud.se)
*/

(function(b){function d(a){this.input=a;a.attr("type")=="password"&&this.handlePassword();b(a[0].form).submit(function(){if(a.hasClass("placeholder")&&a[0].value==a.attr("placeholder"))a[0].value=""})}d.prototype={show:function(a){if(this.input[0].value===""||a&&this.valueIsPlaceholder()){if(this.isPassword)try{this.input[0].setAttribute("type","text")}catch(b){this.input.before(this.fakePassword.show()).hide()}this.input.addClass("placeholder");this.input[0].value=this.input.attr("placeholder")}},
hide:function(){if(this.valueIsPlaceholder()&&this.input.hasClass("placeholder")&&(this.input.removeClass("placeholder"),this.input[0].value="",this.isPassword)){try{this.input[0].setAttribute("type","password")}catch(a){}this.input.show();this.input[0].focus()}},valueIsPlaceholder:function(){return this.input[0].value==this.input.attr("placeholder")},handlePassword:function(){var a=this.input;a.attr("realType","password");this.isPassword=!0;if(b.browser.msie&&a[0].outerHTML){var c=b(a[0].outerHTML.replace(/type=(['"])?password\1/gi,
"type=$1text$1"));this.fakePassword=c.val(a.attr("placeholder")).addClass("placeholder").focus(function(){a.trigger("focus");b(this).hide()});b(a[0].form).submit(function(){c.remove();a.show()})}}};var e=!!("placeholder"in document.createElement("input"));b.fn.placeholder=function(){return e?this:this.each(function(){var a=b(this),c=new d(a);c.show(!0);a.focus(function(){c.hide()});a.blur(function(){c.show(!1)});b.browser.msie&&(b(window).load(function(){a.val()&&a.removeClass("placeholder");c.show(!0)}),
a.focus(function(){if(this.value==""){var a=this.createTextRange();a.collapse(!0);a.moveStart("character",0);a.select()}}))})}})(jQuery);

var ajax_list_search = true;

window.toggleElement = function toggleElement(id) {
    $("#" + id).toggle();
}

window.toggleSearch = function toggleSearch(caller, idPrefix) {
    if (!ajax_list_search) {
        return true;
    }
    var id = (idPrefix || caller.id) + '_search_box';
    toggleElement(id);
}

window.setDisplay = function setDisplay(id,disp) {
    if (document.layers) {
        document.layers[id].display = disp;
    } else if (document.getElementById) {
        document.getElementById(id).style.display = disp;
    } else if (document.all) {
        document.all[id].style.display = disp;
    }
}

// textarea length check
window.checkLen = function checkLen(FieldtoCheck,IDtoShowStatus,maxLength) {
  var leftover = 0;
  (FieldtoCheck.value.length > maxLength ? FieldtoCheck.value = FieldtoCheck.value.substring(0,maxLength) : leftover = maxLength - FieldtoCheck.value.length);
  document.getElementById(IDtoShowStatus).innerHTML = leftover;
}

function set_schedule_visibility(business_unitid) {
    var business_unitid = $("table.editassignment select#business_unitid").val();
    var schedule = $("table.editassignment .schedulesection");

    if (business_unitid == 2){
        schedule.hide();
    } else {
        schedule.show();
    }
}

function clear_form(form_id) {
    $('#' + form_id + ' input[type=text]').val("");
}


function updateDate(obj, targetid) {
    if (!obj) { return; }
    parts = obj.value.split('-');
    document.getElementById(targetid).value = parts[2]+'-'+parts[1]+'-'+parts[0];

    // update evaluation date if on assignment screen:
    var days = $("input#evaluationDays");
    if (days.length > 0) {
        updateEvaluationDate(parseInt(days.val(), 10));
    }
}

function zebralize() {
    /* This selector makes sure only the direct tr's are selected, not the ones in nested tables */
    /* Element tbody may not be defined in HTML but it is in the DOM */
    $("table.zebra > tbody > tr").removeClass("odd");
    $("table.zebra > tbody > tr:nth-child(2n+1)").addClass("odd");
}

$(document).ready(function(){

    zebralize();

    $("a.editinline").click(function(event) {
        event.preventDefault();
        $.get($(this).attr("href"), function(data) {
            $("div.editinline").html(data);
        });
    });
    $("div.editinline button.close").on("click", function(event){
        event.preventDefault();
        $("div.editinline").html("");
    });

    $("a.addclientcontacts").click(function(event){
        event.preventDefault();
        $("div.addclientcontacts").toggle();
    });

    $("form.clientcontactsearch").submit(function(event){
        event.preventDefault();
        $.post($(this).attr("action"), $(this).serialize(), function(data) {
            $("div.searchclientcontacts").html(data);
            zebralize();
        });
    });

    $("form.companysearch").submit(function(event){
        event.preventDefault();
        $.post($(this).attr("action"), $(this).serialize(), function(data) {
            $("div.companysearch").html(data);
            $("form.companysearch").find("input[name=companyid]").val("");
        });
    });

    $("input#inv_priority").hide();
    $("input#priority").click(function(){
        $("input#inv_priority").attr("checked",this.checked);
    });

    set_schedule_visibility();
    $("table.editassignment select#business_unitid").change(function(){
        set_schedule_visibility();
    });

    $("form.vacation").submit(function() {
        var hours = $(this).find("input.hours").val().trim();
        var days = $(this).find("input.days").val().trim();
        if (hours == "" || days == "") {
            alert("Both hours and days should be filled in.");
            return false;
        }
        return true;
    });

    // scroll to particular position
    if (window.location.hash) {
        var anchor = window.location.hash.substr(1);
        if (isNumber(anchor)) {
            $(window).scrollTop(anchor);
        }
    }

    // add scroll position to link
    $("td.allok form").submit(function() {
        $(this).find("input[name=scrollposition]").val($(window).scrollTop());
    });

    // add resizing to textareas
    $("textarea.resizing").each(function(){
		$(this).extend($.extranet.textareaResizer).initialize({});
	});

    // Automatic CRM search; either through get request or ajax.
    $("form.crmsearch").each(function() {
        $(this).extend($.extranet.autoCRMSearch).initialize({});
    });

    // Notes on employee dashboard
    $("div.notes").each(function() {
        $(this).extend($.extranet.smartNotes).initialize({});
    });
	$("button.allnotes").click(function() {
	    $("div.notes tr.notehidden").show();
	    $(this).hide();
    });

	$("form.pictureuploadform").submit(function() {
	    var container = $(this);
        container.find("img.loading").css("display", "inline-block");
        setTimeout(function(){ // Put at the end of the event queue, else file won't be submitted.
            container.find("input[name=file]").attr("disabled", "disabled");
        }, 0);
        container.find("input[type=submit],input[type=button]").attr("disabled", "disabled");
	});

	// Invoice specifications: edit terms
    $("div.terms").each(function() {
        $(this).extend($.extranet.editTerms).initialize({});
    });

    // Uncheck and disable employee_selectable checkbox if assignment gets cancelled.
    $("input#inv_cancelled").extend($.extranet.checkboxDisabler).initialize({
        targetCheckboxElement: "input#employee_selectable",
        targetLabelElement: "label[for=employee_selectable]"
    });
    // When clicking 'charge for cancellation', checkbox 'cancelled' should be checked
    $("input#inv_cancelled_costs").extend($.extranet.checkOther).initialize({
        targetCheckboxElement: "input#inv_cancelled"
    });
    // When unchecking 'cancel' on assignment edit, also uncheck cancelled_cost
    $("input#inv_cancelled").extend($.extranet.uncheckOther).initialize({
        targetCheckboxElement: "input#inv_cancelled_costs"
    });

    // Clear nearest input field
    $("a.clear_nearest").click(function(event){
        $(this).prevAll("input").val("");
        event.preventDefault();
    });

    $("input.note[title]").each(function() {
        $(this).attr("placeholder", $(this).attr("title"));
        $(this).removeAttr("title");
    })
    $("input[placeholder]").placeholder();

    /* Employee report */
    $("form[name=employee_report] input, form[name=employee_report] select").change(function(event){
        $("form[name=employee_report]").submit();
    })

    /* Copy/paste billing address */
    $("form#copy_billing_address").submit(function(event){
        $.post("?" + $(this).attr("action"), $(this).serialize(), function(data){
            if (data.success) {
                $("span#copypaste_messages").html("Billing address copied. Note: billing address should be saved before copying.");
            } else {
                $("span#copypaste_messages").html("Billing address could not be copied!");
            }
        });
        event.preventDefault();
    });

    $("form#paste_billing_address").submit(function(event){
        $.get("?" + $(this).attr("action"), function(data) {
            if (data == "FAIL") {
                $("span#copypaste_messages").html("Billing address could not be pasted!");
            } else {
                $("span#copypaste_messages").html("Billing address pasted.");
                $('form#billing_address_form input[type=text], form#billing_address_form textarea').each(function(){
                    console.log(data[this.id]);
                    console.log($(this));
                    $(this).val(data[this.id]);
                });
            }
        });
        event.preventDefault();
    });

});

function setCompanyId(companyId) {
    $("form.companysearch").find("input[name=companyid]").val(companyId);
    $("form.companysearch").submit();
}

function isNumber(val) {
    return /^-?((\d+\.?\d?)|(\.\d+))$/.test(val);
}

$(function() {

    // Process tooltips
    enableTooltips();

    $.each(["employee_names", "itinerary"], function(i, option) {
        // Update invoice preview iframe if options change
        $("form.invoice input[name="+option+"]").change(function() {
            var newValue = $(this).is(":checked") ? "1" : "0";
            var previewUrl = $("iframe.invoicepreview").attr("src");
            var newUrl = previewUrl.replace(new RegExp(option + "=\\d+"), option+"="+newValue);
            $("iframe.invoicepreview").attr("src", newUrl);
        });
    });

    // Add click event to 'today' link
    $("div.details a.today").click(function() {
        var today = new Date();
        var year = today.getFullYear();
        var month = pad(today.getMonth()+1, 2, "0");
        var day = pad(today.getDate(), 2, "0");

        $("#date").val(year + '-' + month + '-' + day);
        $("#tmp_date").val(day + '-' + month + '-' + year);
        $("#priority").attr("checked", "checked");
        $("#priority_value").val("1");
        return false;
    });

});

function enableTooltips() {
    enableSingleTooltip("a#assignmentnotes", "assignmentnotes-tooltip");
    enableSingleTooltip("a#clientschedulenotes", "clientschedulenotes-tooltip");
    enableSingleTooltip("a#officenotes", "officenotes-tooltip");
    enableSingleTooltip("a#invoicenotes", "invoicenotes-tooltip");
    enableSingleTooltip("a#publishstatus", "publishstatus-tooltip");
    enableSingleTooltip("a#outsidecontractstatus", "outsidecontractstatus-tooltip");
    enableSingleTooltip("a#tobecreditedstatus", "tobecreditedstatus-tooltip");
}

function enableSingleTooltip(hoverSelector, tooltipId) {
    $(hoverSelector).mouseover(function() {
        showTip(tooltipId);
    }).mouseout(function() {
        hideTip();
    });
}

// Helper function to pre-pad values with a certain character.
function pad(input, length, char) {
    input += ''; // force conversion to string
    while (input.lengtemplh < length) {
        input = char + input;
    }
    return input;
}

// Log a message to the console if the browser supports it.
function log(message) {
    if(console) {
        console.log(message);
    }
}

$.extranet = {};

$.extranet.ajaxTableSorters = {
    sorterSelector: "th.sorter a",
    initialize: function() {
        this.sorters = $(this.sorterSelector, $(this));
        this.handleClick();
    },
    handleClick: function() {
        var _this = this;
        $(this.sorters).click(function(event) {
            event.preventDefault();
            $.get("?ajaxrequest=true&" + $(this).attr("href"), function(data) {
                $(_this).html(data);
                $(_this).extend($.extranet.ajaxTableSorters).initialize({});
                zebralize();
            });
        });
    }
};

$.extranet.textareaResizer = {
    initialize: function() {
        this.paddingCorrection = this.getPaddingCorrection();
        $(this).bind("keyup keydown change click", $.proxy(function(){
            this.updateHeight();
        }, this));
    },
    // Every browser has different calculation of scrollHeight+padding+whatnot, so these numbers are used to
    // correct for that. It's not pretty but it works.
    getPaddingCorrection: function() {
        if ($.browser.webkit || $.browser.safari) {
            return 4;
        } else if ($.browser.mozilla) {
            return 1;
        } else {
            return 5;
        }
    },
    updateHeight: function() {
        $(this).css("height", "");
        $(this).css("height", ($(this)[0].scrollHeight - this.paddingCorrection) + "px");
    }
}

$.extranet.autoCRMSearch = {
    duration: 1000,
    ajaxLoaderImage: "img.loading",
    initialize: function() {
        this.autoSubmitTimer = 0;
        this.ajaxLoader = this.find(this.ajaxLoaderImage);
        // After page load, automatically put focus in search bar
        this.find("input#crmsearch").focus();
        // Onkeyup, start a timeout
        this.find("input#crmsearch").keyup($.proxy(function(event){
            var keyCode = event.which;
            var alphaKey = (keyCode >= 65 && keyCode <= 90);
            var numericKey = (keyCode >= 48 && keyCode <= 57)
            if (alphaKey || numericKey) {
                clearTimeout(this.autoSubmitTimer);
                this.hideLoader();
                this.autoSubmitTimer = setTimeout($.proxy(function(){
                  if ($("div.crmsearchresults").size() > 0) {
                    this.showLoader();
                    // Use ajax when available
                    $.get("?ajaxrequest=true&" + $(this).serialize(), $.proxy(function(data) {
                        $("div.crmsearchresults").html(data);
                        this.hideLoader();
                        zebralize();
                    }, this));
                  } else {
                    // Submit the form (sending a get request)
                    this.showLoader();
                  $(this).submit();
                  }
                }, this), this.duration);
            }
        }, this));
    },
    showLoader: function() {
        this.ajaxLoader.show();
    },
    hideLoader: function() {
        this.ajaxLoader.hide();
    }
}

$.extranet.noteLine = {
    editFormSelector: "form.editnote",
    deleteFormSelector: "form.deletenote",
    updateButtonSelector: "input.update",
    deleteButtonSelector: "input.delete",
    editButtonSelector: "button.edit",
    cancelButtonSelector: "button.cancel",
    editSelector: "div.edit",
    readSelector: "div.read",
    authorSelector: "td.author",
    editNoteFieldSelector: "input.note",
    confirmMessage: "Are you sure?",

    initialize: function() {
        this.editForm = this.find(this.editFormSelector);
        this.deleteForm = this.find(this.deleteFormSelector);
        this.editAction = this.editForm.attr("action");
        this.deleteAction = this.deleteForm.attr("action");
        this.updateButton = this.find(this.updateButtonSelector);
        this.editButton = this.find(this.editButtonSelector);
        this.cancelButton = this.find(this.cancelButtonSelector);
        this.deleteButton = this.find(this.deleteButtonSelector);
        this.editElement = this.find(this.editSelector);
        this.readElement = this.find(this.readSelector);
        this.authorElement = this.find(this.authorSelector);
        this.editNoteField = this.find(this.editNoteFieldSelector);

        $.proxy(this.attachHandlers(), this);
    },
    attachHandlers: function() {
        this.editButton.click($.proxy(function() {
            this.showEditForm();
        }, this));
        this.cancelButton.click($.proxy(function() {
            this.hideEditForm();
        }, this));
        this.editForm.submit($.proxy(function(event) {
            event.preventDefault();
            if (this.editNoteField.val().length < 1) {
                return false;
            }
            this.updateNote();
        }, this));
        this.deleteButton.click($.proxy(function(event) {
            event.preventDefault();
            if (confirm(this.confirmMessage)) {
                this.deleteNote();
            }
        }, this));
    },
    showEditForm: function() {
        this.readElement.hide();
        this.editElement.show();
        this.editNoteField.focus().select();
    },
    hideEditForm: function() {
        this.readElement.show();
        this.editElement.hide();
    },
    updateNote: function() {
      $.ajax({
        type: "POST",
        url: this.editAction,
        data: this.editForm.serialize(),
        success: $.proxy(function(data) {
          if (data.success === true) {
            this.readElement.html(data.note.bodytext);
            this.authorElement.html(data.note.author);
            this.hideEditForm();
          }
        }, this)
      });
    },
    deleteNote: function() {
        $.post(this.deleteAction, this.deleteForm.serialize(), $.proxy(function(data) {
            if (data.success === true) {
                this.remove();
            }
        }, this));
    }
}

$.extranet.smartNotes = {
    newFormSelector: "form.newnote",
    newButtonSelector: "input.new",
    newNoteFieldSelector: "input.note",
    noteListSelector: "table.notes",
    noteListHeaderSelector: "tr.head",
    noteSelector: "tr.note",
    initialize: function() {
        this.newForm = this.find(this.newFormSelector);
        this.newAction = this.newForm.attr("action");
        this.newButton = this.find(this.newButtonSelector);
        this.noteListElement = this.find(this.noteListSelector);
        this.noteElement = this.noteListElement.find(this.noteSelector);
        this.noteListHeaderElement = this.noteListElement.find(this.noteListHeaderSelector);
        this.newNoteField = this.find(this.newFormSelector + " " + this.newNoteFieldSelector);

        $(this.noteElement).each(function(){
            $(this).extend($.extranet.noteLine).initialize({});
        });

        this.attachHandlers();
    },
    attachHandlers: function() {
        this.newForm.submit($.proxy(function(event) {
            event.preventDefault();
            if (this.newNoteField.val().length < 1) {
                this.newNoteField.focus();
                return false;
            }
            this.createNote();
        }, this));
    },
    createNote: function() {
        $.post(this.newAction, this.newForm.serialize(), $.proxy(function(data) {
            if (data.length > 0) {
                this.noteListHeaderElement.after(data);
                this.noteListElement.find(this.noteSelector + ":first").extend($.extranet.noteLine).initialize({});
                zebralize();
            }
        }, this));
        this.newNoteField.val("");
    }
}

$.extranet.editTerms = {
    textContainerSelector: "div.text",
    formContainerSelector: "div.form",
    textareaSelector: "textarea",
    saveButtonSelector: "button.save",
    cancelButtonSelector: "button.cancel",
    defaultText: "Click to add...",
    action: "editterms",
    initialize: function() {
        this.textElement = $(this).find(this.textContainerSelector);
        this.formElement = $(this).find(this.formContainerSelector);
        this.textareaElement = $(this).find(this.textareaSelector);
        this.saveButton = $(this).find(this.saveButtonSelector);
        this.cancelButton = $(this).find(this.cancelButtonSelector);

        this.objectType = this.findObjectType();
        this.objectId   = $("input[name="+this.objectType+"id]").val();
        this.postUrl    = "?o="+this.objectType+"&a="+this.action+"&"+this.objectType+"id="+this.objectId;

        this.attachHandlers();
    },
    attachHandlers: function() {
        var _this = this;
        this.textElement.click(function() {
            _this.showForm();
        });
        this.cancelButton.click(function(event) {
            event.preventDefault();
            _this.cancelEdit();
        });
        this.saveButton.click(function(event) {
            event.preventDefault();
            _this.saveData();
        });
        // To make sure the cursor goes to the end of the line, copy and paste the textarea's contents
        this.textareaElement.focus(function() {
            this.contents = $(this).val();
            $(this).val("").val(this.contents);
        });

    },
    showForm: function() {
        $(this.textElement).hide();
        $(this.formElement).show();
        this.textareaElement.focus();
    },
    showText: function() {
        $(this.textElement).show();
        $(this.formElement).hide();
    },
    cancelEdit: function() {
        var text = $(this.textElement).html().trim();
        if (text != this.defaultText) {
            $(this.textareaElement).val(text); // Replace modified text in textarea with old content of div.text
        } else {
            $(this.textareaElement).val(""); // Default text means textarea should be empty
        }
        this.showText();
    },
    findObjectType: function() {
        var objectType = this.textareaElement.attr("name");
        return objectType.substr(0, objectType.lastIndexOf("_")); // Cut off '_terms'
    },
    saveData: function() {
        var _this = this;
        $.post(_this.postUrl, { terms: _this.textareaElement.val() }, function (returnData) {
            if (returnData != "") {
                $(_this.textElement).html(returnData);
            } else {
                $(_this.textElement).html(_this.defaultText);
            }
            _this.showText();
        })
    }
}

// Note: this probably doesn't work very well with multiple target checkboxes
$.extranet.checkboxDisabler = {
    options: {
        targetCheckboxElement: "input[type=checkbox]",
        targetLabelElement: "label[for=checkbox]",
        disableClass: "disabled",
        switchOff: true
    },
    initialize: function(options) {
        this.options = $.extend(this.options, options);
        this.targetCheckbox = $(this.options.targetCheckboxElement);
        this.targetLabel = $(this.options.targetLabelElement);
        this.attachHandlers();
    },
    attachHandlers: function() {
        var _this = this;
        $(this).bind("click bubble", function() {
            if ($(_this).is(":checked")) {
                _this.switchOff();
            } else {
                _this.switchOn();
            }
        });
    },
    switchOff: function() {
        this.targetIsChecked = this.targetCheckbox.is(":checked");
        this.targetCheckbox.attr({disabled: "disabled"});
        this.targetLabel.addClass(this.options.disableClass);
        if (this.options.switchOff) {
            this.targetCheckbox.removeAttr("checked");
        }
    },
    switchOn: function() {
        this.targetCheckbox.removeAttr("disabled");
        this.targetLabel.removeClass(this.options.disableClass);
        if (this.options.switchOff && this.targetIsChecked) {
            this.targetCheckbox.attr({checked: "checked"});
        }
    }
};

$.extranet.checkOther = {
    options: {
        targetCheckboxElement: "input[type=checkbox]"
    },
    initialize: function(options) {
        this.options = $.extend(this.options, options);
        this.targetCheckbox = $(this.options.targetCheckboxElement);
        this.attachHandlers();
    },
    attachHandlers: function() {
        var _this = this;
        $(this).click(function() {
            if ($(_this).is(":checked")) {
                if (!_this.targetCheckbox.is(":checked")) {
                    _this.targetCheckbox.attr({checked: "checked"});
                    _this.targetCheckbox.trigger("bubble");
                    _this.targetCheckbox.trigger("change");
                }
            }
        });
    }
};

$.extranet.uncheckOther = {
    options: {
        targetCheckboxElement: "input[type=checkbox]"
    },
    initialize: function(options) {
        this.options = $.extend(this.options, options);
        this.targetCheckbox = $(this.options.targetCheckboxElement);
        this.attachHandlers();
    },
    attachHandlers: function() {
        var _this = this;
        $(this).click(function() {
            if (!$(_this).is(":checked")) {
                if (_this.targetCheckbox.is(":checked")) {
                    _this.targetCheckbox.removeAttr("checked");
                    _this.targetCheckbox.trigger("bubble");
                    _this.targetCheckbox.trigger("change");
                }
            }
        });
    }
}

$.extranet.scheduleRow = {
  swapDownSelector: "button.swapdown",
  swapUpSelector: "button.swapup",
  removeSelector: "input.remove",
  baseParams: { o: "ajax_schedule" },
  initialize: function() {
      this.scheduleId = $(this).attr("id").substring(8); // "schedule"
      this.swapDownButton = this.find(this.swapDownSelector);
      this.swapUpButton = this.find(this.swapUpSelector);
      this.removeButton = this.find(this.removeSelector);
      this.attachHandlers();
  },
  reinitialize: function(data) {
      $("#schedule").html(data.schedule);
      if (data.updatedTimes.updated) {
          var newStart = data.updatedTimes.start;
          var newFinish = data.updatedTimes.finish;
          $("input#start_time0").val(newStart.hours);
          $("input#start_time1").val(newStart.minutes);
          $("input#finish_time0").val(newFinish.hours);
          $("input#finish_time1").val(newFinish.minutes);
          this.alertTimeBox($("input#start_time0"));
          this.alertTimeBox($("input#start_time1"));
          this.alertTimeBox($("input#finish_time0"));
          this.alertTimeBox($("input#finish_time1"));
      }
      initScheduleEvents();
      if (!$(':focus').length) {
        $("#" + window.nextFocus.id).focus();
      }
  },
  alertTimeBox: function(input) {
      var highlightColor = "#89ed61";
      var disabledBackground = "#eee";
      var fadeOutTime = 1500;
      var fadeInTime = 250;
      input
          .animate( { "background-color": highlightColor }, fadeInTime)
          .animate( { "background-color": disabledBackground }, fadeOutTime);
  },
  attachHandlers: function() {
      this.swapDownButton.click($.proxy(function(event) {
          event.preventDefault();
          this.swapDown(event);
      }, this));
      this.swapUpButton.click($.proxy(function(event) {
          event.preventDefault();
          this.swapUp(event);
      }, this));
      this.removeButton.click($.proxy(function(event) {
          event.preventDefault();
          this.remove(event);
      }, this));

      this.hover(function() {
          $(this).addClass("hover");
      }, function() {
          $(this).removeClass("hover");
      });

      // handler for enabling/disabling auto update times
      this.bind("enable", $.proxy(function() {
         this.toggle(true);
      }, this));

      this.bind("disable", $.proxy(function() {
         this.toggle(false);
      }, this));

      // Add events to inputs
      var inputs = this.find("td.editable input");
      var row = this;
      $(inputs).each(function() {
          $(this).extend($.extranet.scheduleRowInput).initialize({ row: row });
      });

  },
  swapDown: function() {
      var nextScheduleId = $(this).next("tr.inline").attr("id").substring(8);
      this.swap(this.scheduleId, nextScheduleId);
  },
  swapUp: function() {
      var prevScheduleId = $(this).prev("tr.inline").attr("id").substring(8);
      this.swap(this.scheduleId, prevScheduleId);
  },
  swap: function(one, two) {
    var values = {
        "a": "swap",
        "scheduleid": one,
        "scheduleid2": two,
    };
    var requestParams = $.extend(this.baseParams, values);
    this.doPost(requestParams);
  },
  remove: function() {
      var values = {
          "a": "remove",
          "scheduleid": this.scheduleId
      };
      var requestParams = $.extend(this.baseParams, values);
      this.doPost(requestParams);
  },
  update: function() {
      var baseParams = {
          o: "ajax_schedule",
          a: "update"
      };
      var arrivalMinute = this.find("td.arrival input.minute").val();
      var departureMinute = this.find("td.departure input.minute").val();
      arrivalMinute = arrivalMinute ? arrivalMinute : "00";
      departureMinute = departureMinute ? departureMinute : "00";
      var values = {
          "arrival_time[0]": this.find("td.arrival input.hour").val(),
          "arrival_time[1]": arrivalMinute,
          "departure_time[0]": this.find("td.departure input.hour").val(),
          "departure_time[1]": departureMinute,
          "schedulenotes": this.find("td.notes input.schedulenotes").val(),
          "scheduleid": this.find("td.notes input.scheduleid").val()
      }
      var requestParams = $.extend(baseParams, values);
      this.doPost(requestParams);
  },
  toggle: function(enable) {
      var values = {
          "a": "toggle",
          "enable": enable ? "1" : "0",
          "assignmentid": $("#assignmentid").val()
      };
      var requestParams = $.extend(this.baseParams, values);
      this.doPost(requestParams);
  },
  doPost: function(requestParams) {
      var row = this;
      $.post("/admin/extranet.php", requestParams, function(data) {
          row.reinitialize(data);
      });
  }
}

$.extranet.scheduleRowInput = {
  initialize: function(args) {
      this.row = args.row;

      // remove time divider if empty hour
      if ($(this).hasClass("hour")) {
          if ($(this).val() == "") {
              $(this).siblings(".timedivider:visible").hide();
          }
      }
      this.attachHandlers();
  },
  attachHandlers: function() {
      // show input fields upon focus
      this.focus($.proxy(function() {
          this.doFocus();
      }, this));
      // update values on server if focus was lost
      this.blur($.proxy(function() {
          this.doBlur();
      }, this));
  },
  doFocus: function() {
      $(this).addClass("focus");
      window.nextFocus = this.get(0);
      this.row.find("td.editable input").each(function(){
          $(this).addClass("editing");
      });
      this.row.find(".timedivider:hidden").show();
  },
  doBlur: function() {
      $(this).removeClass("focus");
      window.nextFocus = this.row.next().find('td.arrival input.hour').get(0);
      if (!window.nextFocus) {
        window.nextFocus = this.row.siblings().first().find('td.arrival input.hour').get(0);
      }
      setTimeout($.proxy(function() {
          var focusedInputs = this.row.find("input.focus");
          var lostFocus = focusedInputs.length==0;
          if (lostFocus) {
              this.row.update();
          }
      }, this), 0);
  }
}
