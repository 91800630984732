var AssignmentStatusOverview;

AssignmentStatusOverview = class AssignmentStatusOverview {
  constructor(el) {
    this.attachEvents = this.attachEvents.bind(this);
    this.initFilters = this.initFilters.bind(this);
    this.search = this.search.bind(this);
    this.fetchAssignments = this.fetchAssignments.bind(this);
    this.updateAssignments = this.updateAssignments.bind(this);
    this.el = el;
    this.filters = this.el.find("select.selectize");
    this.table = this.el.find("#assignment-status-results");
    this.currentFilterRequest = 0;
    this.initFilters();
    this.attachEvents();
  }

  attachEvents() {
    return this.el.on('submit', 'form', (e) => {
      e.preventDefault();
      return this.updateAssignments(new FormData(e.target));
    });
  }

  initFilters() {
    return this.selectizedElements = this.filters.map((index, field) => {
      return jQuery(field).selectize({
        create: false,
        onChange: (value) => {
          return this.search(value, event);
        }
      });
    });
  }

  search(value, event) {
    var dataType, element, path;
    element = $(event.target);
    dataType = element.closest('.filter').data('type');
    $('.page-loader').css('display', 'flex');
    this.selectizedElements.each((index, field) => {
      if (dataType === "year" && field.parent().data('type') !== "year" && field[0].value !== '') {
        value = field[0].value;
        return dataType = field.parent().data('type');
      } else if (!["year", dataType, '', void 0].includes(field.parent().data('type')) && field[0].value !== '') {
        return field[0].selectize.setValue('', true);
      }
    });
    path = location.pathname + `?year=${this.el.find('#year').val()}&sequence=${value}&period=${dataType}`;
    window.history.replaceState(path, jQuery("head title").html(), path);
    return this.fetchAssignments(value, dataType);
  }

  fetchAssignments(value, dataType) {
    return $.get('/admin/finance/assignment_status.partial', {
      year: this.el.find('#year').val(),
      sequence: value,
      period: dataType
    }, (data) => {
      return this.table.html(data);
    });
  }

  updateAssignments(data) {
    $('.page-loader').css('display', 'flex');
    return $.ajax({
      url: '/admin/finance/assignment_status',
      method: "PATCH",
      data: data,
      processData: false,
      contentType: false,
      success: () => {
        return window.location.reload(false);
      }
    });
  }

};

$(function() {
  var overview;
  overview = jQuery.find('#assignment-status-overview');
  if (overview.length > 0) {
    return new AssignmentStatusOverview(jQuery(overview));
  }
});
