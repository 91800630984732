import "../stylesheets/admin.scss";

import "./imports/jquery";

import "jquery-ui/ui/widgets/datepicker";

import {} from 'jquery-ujs';

import "chartkick/chart.js";

import "chartjs-plugin-datalabels";

import "chartjs-plugin-annotation";

import "flatpickr";

import "magnific-popup";

import "tinymce/tinymce";

import "selectize";

require('jszip');

require('datatables.net')(window, $);

require('datatables.net-buttons-dt')();

require('datatables.net-buttons/js/buttons.html5.js')();

import annotationPlugin from "chartjs-plugin-annotation";

import datalabelsPlugin from "chartjs-plugin-datalabels";

import {
  Chart
} from 'chart.js';

require('chartkick/chart.js');

require('chartjs-plugin-annotation');

require('chartjs-plugin-datalabels');

Chart.register(annotationPlugin, datalabelsPlugin);

import "./vendor/global/nested_form";

import "./vendor/global/colorpicker";

import "./vendor/global/jquery-debounce";

import "./vendor/global/jquery-migrate";

import "./vendor/global/jquery.timepicker";

import "./vendor/global/jszip.min";

import "./vendor/global/rangeslider.min";

import "./vendor/global/spectrum.min";

import "./vendor/calendar/zapatec.js";

import "./vendor/calendar/calendar.js";

import "./vendor/calendar/calendar-en.js";

import "./shared/magnific_popup";

import "./shared/php";

import "./shared/tooltip";

import "./admin/finance/invoice_specification";

require("./shared/applicable_surcharge.coffee");

require("./shared/date_helper.coffee");

require("./shared/location_autocomplete.coffee");

require("./shared/locations_planner.coffee");

require("./shared/menu.coffee");

require("./shared/new_assignment.coffee");

require("./shared/planning_tooltip.coffee");

require("./shared/time_range_controller.coffee");

require('./shared/schedule_and_time_sheets_controller.coffee');

require("./admin/auto_submit.coffee");

require("./admin/datatables.coffee");

require("./admin/datepicker.coffee");

require("./admin/navigation.coffee");

require("./admin/notices.coffee");

require("./admin/php_initializer.coffee");

require("./admin/spectrum.coffee");

require("./admin/table_row_links.coffee");

require("./admin/crm/companies.coffee");

require("./admin/finance/afas.coffee");

require("./admin/finance/assignment_status_overview.coffee");

require("./admin/finance/contract.coffee");

require("./admin/finance/invoice_preview.coffee");

require("./admin/finance/invoicing_overview.coffee");

require("./admin/finance/invoicing_overview_assignment.coffee");

require("./admin/finance/invoicing_overview_contact.coffee");

require("./admin/finance/salaries.coffee");

require("./admin/hrm/absence_summary.coffee");

require("./admin/hrm/dashboard.coffee");

require("./admin/hrm/employee.coffee");

require("./admin/hrm/employee_contract.coffee");

require("./admin/hrm/employee_overview.coffee");

require("./admin/hrm/hours_summary.coffee");

require("./admin/hrm/vacations.coffee");

require("./admin/operations/assignments.coffee");

require("./admin/operations/contact_search.coffee");

require("./admin/operations/assignment_blueprints/index.coffee");

require("./admin/operations/assignment_exports/export.coffee");

require("./admin/operations/assignment_surcharges/manual_surcharge.coffee");

require("./admin/operations/assignments/assignment_form.coffee");

require("./admin/operations/assignments/changelog.coffee");

require("./admin/operations/assignments/overview.coffee");

require("./admin/operations/unassigned/unassigned_controller.coffee");

require("./admin/other/knowledge_base.coffee");

require("./admin/other/service_type.coffee");

require("./admin/other/user.coffee");

require("./admin/other/job_group/new.coffee");

require("./admin/user_management/user_overview.coffee");

import "./admin/php/ajax_script";

import "./admin/php/assignmentform";

import "./admin/php/dashboard";

import "./admin/php/global";

import "./admin/php/invoiceajax";

import "./admin/php/invoices";

import "./admin/php/job_groupform";

import "./admin/php/loading_spinner";

import "./admin/php/quick";

import "./admin/php/unpaidinvoices";

require("./admin/reporting/dashboard.coffee");

require("./admin/reporting/gross_margin.coffee");

require("./admin/reporting/filter_bar.coffee");
