if (!$.extranet) {
  $.extranet = {}
}

$.extranet.editTerms = {
  textContainerSelector: "div.text",
  formContainerSelector: "div.form",
  textareaSelector: "textarea",
  saveButtonSelector: "button.save",
  cancelButtonSelector: "button.cancel",
  defaultText: "Click to add...",
  action: "editterms",
  initialize: function () {
    this.textElement = $(this).find(this.textContainerSelector)
    this.formElement = $(this).find(this.formContainerSelector)
    this.textareaElement = $(this).find(this.textareaSelector)
    this.saveButton = $(this).find(this.saveButtonSelector)
    this.cancelButton = $(this).find(this.cancelButtonSelector)

    this.objectType = this.findObjectType()
    this.objectId = $("input[name=" + this.objectType + "id]").val()
    this.postUrl =
      "/admin/extranet.php?o=" +
      this.objectType +
      "&a=" +
      this.action +
      "&" +
      this.objectType +
      "id=" +
      this.objectId

    this.attachHandlers()
  },
  attachHandlers: function () {
    var _this = this
    this.textElement.click(function () {
      _this.showForm()
    })
    this.cancelButton.click(function (event) {
      event.preventDefault()
      _this.cancelEdit()
    })
    this.saveButton.click(function (event) {
      event.preventDefault()
      _this.saveData()
    })
    // To make sure the cursor goes to the end of the line, copy and paste the textarea's contents
    this.textareaElement.focus(function () {
      this.contents = $(this).val()
      $(this).val("").val(this.contents)
    })
  },
  showForm: function () {
    $(this.textElement).hide()
    $(this.formElement).show()
    this.textareaElement.focus()
  },
  showText: function () {
    $(this.textElement).show()
    $(this.formElement).hide()
  },
  cancelEdit: function () {
    var text = $(this.textElement).html().trim()
    if (text != this.defaultText) {
      $(this.textareaElement).val(text) // Replace modified text in textarea with old content of div.text
    } else {
      $(this.textareaElement).val("") // Default text means textarea should be empty
    }
    this.showText()
  },
  findObjectType: function () {
    var objectType = this.textareaElement.attr("name")
    return objectType.substr(0, objectType.lastIndexOf("_")) // Cut off '_terms'
  },
  saveData: function () {
    var _this = this
    $.post(_this.postUrl, {terms: _this.textareaElement.val()}, function (returnData) {
      if (returnData != "") {
        $(_this.textElement).html(returnData)
      } else {
        $(_this.textElement).html(_this.defaultText)
      }
      _this.showText()
    })
  },
}

$("div.terms").each(function () {
  $(this).extend($.extranet.editTerms).initialize({})
})

// Legacy code below
function formIsDisabled() {
  return $.parseJSON($("form#invoice").data("disabled"))
}

function enableForm() {
  $("form#invoice").data("disabled", "false")
  $("form#invoice").find("*").removeAttr("disabled")
}

//copies all declaration values to invoice boxes
function copyFieldValues() {
  if (formIsDisabled()) return
  $("form#invoice")
    .find("input")
    .each(function () {
      var claimId = $(this).attr("id")
      var invoiceId = claimId && claimId.replace(/^assignment_claim_/, "assignment_inv_")
      if (claimId != invoiceId) {
        $(document.getElementById(invoiceId)).val($(this).val())
      }
    })
}

$(function () {
  if ($("form#invoice").length == 0) return

  if (formIsDisabled()) {
    $("form#invoice").find("*:not(.enabled)").attr({disabled: "disabled"})
  }

  if (window.location.href.endsWith("#enable")) {
    enableForm()
  } else {
    $("#enable_invoice_form").click(enableForm)
  }
})
