var syncEntity, syncNextEntity;

syncEntity = function(row, callback) {
  var id, operation, type;
  row.next().hide().find("td").html(""); // reset error
  type = row.attr("data-type");
  operation = row.attr("data-operation");
  id = row.attr("data-id");
  row.find("td.action button").hide();
  row.find("td.action img").show();
  return jQuery.ajax({
    type: "POST",
    url: "/admin/api/notifications/client",
    data: "type=" + type + "&operation=" + operation + "&id=" + id,
    success: (data) => {
      row.find("td.action button").remove();
      row.find("td.action img").hide();
      row.find("td.action span.success").show();
      if (callback) {
        return callback();
      }
    },
    error: (xhr, ajaxOptions, thrownError) => {
      row.find("td.action img").hide();
      if (!callback) {
        row.find("td.action button").show();
      }
      row.next().find("td").html("<strong>" + type.charAt(0).toUpperCase() + type.slice(1) + " couldn't be " + operation + "d in AFAS</strong><br/><br/>" + xhr.responseJSON.error);
      row.next().show();
      row.addClass("processed"); // Add class to make sure that it doesn't get processed again and again
      if (callback) {
        return callback();
      }
    }
  });
};

syncNextEntity = function() {
  var row;
  if (jQuery("tr[class!='processed'] td.action button:visible").length > 0) {
    row = jQuery("tr[class!='processed'] td.action button:visible:first").parents("tr");
    return syncEntity(row, syncNextEntity);
  }
};

$(function() {
  var showLoading, toggleRows;
  jQuery("button.sync").on("click", function(event) {
    var row;
    row = jQuery(event.target).parents("tr");
    return syncEntity(row);
  });
  jQuery("div.syncall button").on("click", function(event) {
    return syncNextEntity();
  });
  if (jQuery("table.finance-status").length > 0) {
    showLoading = function(message) {
      jQuery(".afas-data-container").empty();
      jQuery(".spinner-container span").html(message);
      jQuery(".spinner-container").show();
      return jQuery('.spinner-container .spinner').show();
    };
    toggleRows = function(hideSynced) {
      return jQuery("table.finance-status > tbody > tr").each(function(index, element) {
        if (jQuery(element).find("td.toupdate, td.toinsert").length === 0) {
          if (hideSynced) {
            return jQuery(element).hide();
          } else {
            return jQuery(element).show();
          }
        }
      });
    };
    jQuery("input#hide-synced").on("change", function(event) {
      return toggleRows(jQuery(event.target).is(":checked"));
    });
    jQuery("div.bulk-selection .check-all").on("click", function(event) {
      jQuery('input.sync').prop("checked", true);
      return jQuery('tr.employee-row').addClass('checked');
    });
    jQuery("div.bulk-selection .uncheck-all").on("click", function(event) {
      jQuery('input.sync').prop("checked", false);
      return jQuery('tr.employee-row').removeClass('checked');
    });
    jQuery('input.sync').on("change", function(event) {
      if ($(event.target).prop('checked')) {
        return $(event.target).closest('tr').addClass('checked');
      } else {
        return $(event.target).closest('tr').removeClass('checked');
      }
    });
    jQuery("button.sync-finance").on("click", function(event) {
      var employee_ids, month, period, year;
      year = jQuery(event.target).attr("data-year");
      month = jQuery(event.target).attr("data-month") || "";
      period = jQuery(event.target).attr("data-period") || "";
      employee_ids = jQuery('tr.employee-row.checked').map((_, row) => {
        return $(row).data('id');
      }).toArray().join(',');
      showLoading("Synchronizing data and reloading page...please wait");
      return jQuery.ajax({
        type: "POST",
        url: "/admin/finance/afas/salary_status",
        data: "year=" + year + "&month=" + month + "&period=" + period + "&employee_ids=" + employee_ids,
        success: (data) => {
          return location.reload();
        },
        error: (xhr, ajaxOptions, thrownError) => {
          jQuery('.spinner-container .spinner').hide();
          return jQuery('.spinner-container span').html('Something went wrong. Please refresh the page and try again.');
        }
      });
    });
    return jQuery("#year,#month,#period").on("change", function(event) {
      return showLoading("Loading data...please wait");
    });
  }
});
