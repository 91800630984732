var ManualSurchargePopup;

ManualSurchargePopup = class ManualSurchargePopup {
  constructor($el) {
    this.attachEventHandlers = this.attachEventHandlers.bind(this);
    this.$el = $el;
    this.$form = this.$el.find("form");
    this.attachEventHandlers();
  }

  attachEventHandlers() {
    this.$form.on("click", "a.cancel", (event) => {
      return $.magnificPopup.close();
    });
    return this.$form.on("click", "a.submit", (event) => {
      return $.post({
        url: this.$form.attr("action"),
        data: this.$form.serialize(),
        dataType: 'text',
        success: (data) => {
          $("table.surcharges").children().remove();
          $("table.surcharges").append(data);
          return $.magnificPopup.close();
        }
      });
    });
  }

};

$(function() {
  if ($("#manual-surcharge-popup").length > 0) {
    return new ManualSurchargePopup($("#manual-surcharge-popup"));
  }
});
