var Employee;

Employee = class Employee {
  constructor(el) {
    this.attachEvents = this.attachEvents.bind(this);
    this.toggleHourlyRates = this.toggleHourlyRates.bind(this);
    this.el = el;
    this.hourly_rates = $.find('.hourly-rate').length;
    this.attachEvents();
    this.toggleHourlyRates();
  }

  attachEvents() {
    $('#hourly-rates').on("nested:fieldAdded", (e) => {
      $(e.target).find('.datepicker').datepicker();
      this.hourly_rates++;
      return this.toggleHourlyRates();
    });
    return $('#hourly-rates').on("nested:fieldRemoved", (e) => {
      this.hourly_rates--;
      return this.toggleHourlyRates();
    });
  }

  toggleHourlyRates() {
    if (this.hourly_rates <= 0) {
      $('#default-rate').removeClass('hidden');
      return $('#hourly-rates thead').addClass('hidden');
    } else {
      $('#default-rate').addClass('hidden');
      return $('#hourly-rates thead').removeClass('hidden');
    }
  }

};

$(function() {
  var edit_employee;
  edit_employee = jQuery.find('#edit-employee');
  if (edit_employee.length > 0) {
    return new Employee(jQuery(edit_employee));
  }
});
