var EmployeeOverview;

EmployeeOverview = class EmployeeOverview {
  constructor(el) {
    this.initFilters = this.initFilters.bind(this);
    this.attachEvents = this.attachEvents.bind(this);
    this.attachDetailEvents = this.attachDetailEvents.bind(this);
    this.fetchEmployeesByFilter = this.fetchEmployeesByFilter.bind(this);
    this.fetchEmployeeDetails = this.fetchEmployeeDetails.bind(this);
    this.refreshEmployeeDetails = this.refreshEmployeeDetails.bind(this);
    this.loading = this.loading.bind(this);
    this.el = el;
    this.filters = this.el.find("select.selectize");
    this.table = this.el.find("#employee-results");
    this.initFilters();
    this.attachDetailEvents();
    this.attachEvents();
  }

  initFilters() {
    this.filters.each((index, field) => {
      return jQuery(field).selectize({
        create: false,
        onChange: () => {
          return this.fetchEmployeesByFilter();
        }
      });
    });
    return jQuery('#search').keyup(jQuery.debounce(500, this.fetchEmployeesByFilter));
  }

  attachEvents() {
    if (jQuery('tr.main').length === 1) {
      this.toggleEmployeeRow($('tr.main'));
    }
    jQuery('tr.main').on('click', (e) => {
      if (!($(e.target).is('a') || $(e.target).is('i'))) {
        return this.toggleEmployeeRow($(e.target.closest('tr.main')));
      }
    });
    return jQuery('.excel-export').on('click', (e) => {
      return window.location = `/admin/hrm/employees.xlsx?filter=true&type=${this.el.find('#type').val()}&status=${this.el.find('#status').val()}&bu=${this.el.find('#bu').val()}&job=${this.el.find('#job').val()}&search=${this.el.find('#search').val()}`;
    });
  }

  attachDetailEvents() {
    jQuery('#employee-results').on('change', '.edit_employee input', function(e) {
      return $(e.target).closest('form').submit();
    });
    jQuery('#employee-results').on('click', '.assignments-button', (e) => {
      return this.fetchTableData(e, 'assignment');
    });
    jQuery('#employee-results').on('click', '.contracts-button', (e) => {
      return this.fetchTableData(e, 'contract');
    });
    jQuery('#employee-results').on('click', '.portal-views-button', (e) => {
      return this.fetchTableData(e, 'portal_view');
    });
    jQuery('#employee-results').on('click', '.teams-button', (e) => {
      return this.fetchTableData(e, 'team_membership');
    });
    jQuery('#employee-results').on('click', '.edit-service-type', (e) => {
      return this.showServiceTypeModal(e);
    });
    jQuery('#edit-service-type-modal').on('click', '#save-service-type', (e) => {
      $(e.target).parent().parent().find('.double-spinner').show();
      return $.ajax({
        url: $(e.target).parent().find('form').attr('action'),
        method: "PATCH",
        data: {
          employee: {
            blueprint_service_type_id: $(e.target).parent().find('option:selected').val()
          }
        },
        success: (data) => {
          var detailsRow, row;
          row = $('tr.open');
          detailsRow = $("#" + row.attr('id') + "-details");
          this.refreshEmployeeDetails(row, detailsRow);
          return $("button[title='Close (Esc)'").click();
        }
      });
    });
    jQuery('#employee-results').on('click', '.save-note', function(e) {
      var form, new_note;
      form = $(e.target).parent().parent().find('form');
      new_note = {
        note: {
          noteableid: form.find('input[name="note[noteableid]"]').val(),
          noteabletype: form.find('input[name="note[noteabletype]"]').val(),
          body: form.find('input[name="note[body]"]').val()
        }
      };
      return $.ajax({
        url: form.attr('action'),
        method: "POST",
        data: new_note,
        success: (data) => {
          $('tbody.notes-list').prepend(data);
          return $('.new-note-field').val('');
        }
      });
    });
    return jQuery('#employee-results').on('click', '.remove-note', function(e) {
      $(e.target).parent().submit();
      return $(e.target).closest('tr').hide();
    });
  }

  fetchTableData(e, type) {
    $(e.target).addClass('loading');
    return $.get(`/admin/hrm/employees/${$(e.target).data('id')}/employee_${type}s`, (data) => {
      $(`#${type}-container .content`).html(data);
      $(e.target).removeClass('loading');
      return jQuery.magnificPopup.open({
        items: {
          src: `#${type}-container`
        }
      });
    });
  }

  toggleEmployeeRow(row) {
    var detailsRow, opened;
    opened = row.hasClass('open');
    detailsRow = $("#" + row.attr('id') + "-details");
    $('tr.details').hide();
    $('tr.main').removeClass('open');
    if (!opened) {
      if (detailsRow.find('.details-content').length > 0) {
        return this.fetchEmployeeDetails(row, detailsRow);
      } else {
        row.addClass('open');
        return detailsRow.show();
      }
    }
  }

  fetchEmployeesByFilter() {
    this.loading(true);
    return $.get('/admin/hrm/employees', {
      filter: 'true',
      type: this.el.find('#type').val(),
      status: this.el.find('#status').val(),
      bu: this.el.find('#bu').val(),
      job: this.el.find('#job').val(),
      search: this.el.find('#search').val(),
      team: this.el.find('#team').val()
    }, (data) => {
      this.table.html(data);
      window.history.replaceState({}, '', '/admin/hrm/employees');
      this.loading(false);
      return this.attachEvents();
    });
  }

  fetchEmployeeDetails(row, detailsRow) {
    row.addClass('loading');
    return $.get(`/admin/hrm/employees/${row.data('id')}/employee_details`, (data) => {
      detailsRow.find('.details-content').html(data);
      row.removeClass('loading');
      row.addClass('open');
      return detailsRow.show();
    });
  }

  refreshEmployeeDetails(row, detailsRow) {
    return $.get(`/admin/hrm/employees/${row.data('id')}/employee_details`, (data) => {
      return detailsRow.find('.details-content').html(data);
    });
  }

  showServiceTypeModal(e) {
    return $.get(`/admin/hrm/employees/${$(e.target).data('id')}/employee_service_types`, (data) => {
      $("#edit-service-type-modal .content").html(data);
      $("select.selectize.service-type").selectize({
        allowEmptyOption: true,
        create: false
      });
      return jQuery.magnificPopup.open({
        items: {
          src: "#edit-service-type-modal"
        }
      });
    });
  }

  loading(state) {
    if (state) {
      return this.el.find('.double-spinner').show();
    } else {
      return this.el.find('.double-spinner').hide();
    }
  }

};

$(function() {
  var overview;
  overview = jQuery.find('#employee-overview');
  if (overview.length > 0) {
    return new EmployeeOverview(jQuery(overview));
  }
});
