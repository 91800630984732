var ReportingDashboard;

ReportingDashboard = class ReportingDashboard {
  constructor(el) {
    this.initSelectFields = this.initSelectFields.bind(this);
    this.attachHandlers = this.attachHandlers.bind(this);
    this.updateGraphs = this.updateGraphs.bind(this);
    this.getSelectedMetrics = this.getSelectedMetrics.bind(this);
    this.setSelectedMetrics = this.setSelectedMetrics.bind(this);
    this.el = el;
    this.initSelectFields(jQuery(this.el));
    this.attachHandlers(this.el);
  }

  initSelectFields(within) {
    within.find(".selectize").selectize();
    return within.find(".selectize-multiple").selectize({
      maxItems: null
    });
  }

  attachHandlers() {
    jQuery(this.el).find("#show").on("change", (event) => {
      if (jQuery(event.target).val() === "week") {
        return jQuery(this.el).find("#upto")[0].selectize.enable();
      } else {
        return jQuery(this.el).find("#upto")[0].selectize.disable();
      }
    });
    jQuery(this.el).find("#filters select").on("change", (event) => {
      return jQuery(this.el).find("#filters").submit();
    });
    jQuery(this.el).find("#bu_").on("change", (event) => {
      if (jQuery(event.target).val() === "") {
        return jQuery(this.el).find("#user")[0].selectize.enable();
      } else {
        return jQuery(this.el).find("#user")[0].selectize.disable();
      }
    });
    jQuery(this.el).find("#user").on("change", (event) => {
      if (jQuery(event.target).val() === "all") {
        return jQuery(this.el).find("#bu_")[0].selectize.enable();
      } else {
        return jQuery(this.el).find("#bu_")[0].selectize.disable();
      }
    });
    jQuery(this.el).on("submit", "#filters", (event) => {
      var path, selectedMetrics;
      $('.rails-container > .page-loader').css('display', 'flex');
      event.preventDefault();
      path = jQuery(event.target).attr("action") + "?" + jQuery(event.target).serialize();
      window.history.pushState(path, jQuery("head title").html(), path);
      selectedMetrics = this.getSelectedMetrics();
      return jQuery.get(path + "&filter=").done((data, textStatus, jqXHR) => {
        jQuery(this.el).find(".report").replaceWith(data);
        this.initSelectFields(jQuery(this.el).find(".graphs"));
        this.setSelectedMetrics(selectedMetrics);
        $('.page-loader').css('display', 'none');
        if (jQuery(this.el).find(".show-tables").is(":visible")) {
          jQuery(this.el).find(".tables").hide();
          return jQuery(this.el).find(".graphs").show().css("display", "flex");
        }
      });
    });
    jQuery(this.el).find(".show-graphs").on("click", (event) => {
      jQuery(this.el).find(".show-graphs").hide();
      jQuery(this.el).find(".show-tables").css("display", "inline-block");
      jQuery(this.el).find(".tables").hide();
      return jQuery(this.el).find(".graphs").show().css("display", "flex");
    });
    jQuery(this.el).find(".show-tables").on("click", (event) => {
      jQuery(this.el).find(".show-tables").hide();
      jQuery(this.el).find(".show-graphs").css("display", "inline-block");
      jQuery(this.el).find(".graphs").hide();
      return jQuery(this.el).find(".tables").show();
    });
    jQuery(this.el).on("change", ".target", (event) => {
      var inverseTarget, newTarget;
      newTarget = parseFloat(jQuery(event.target).val());
      inverseTarget = jQuery(event.target).parents("tr").attr("data-inverse") === "true";
      jQuery(event.target).parents("tr").find("div.value").each(function(index, element) {
        var value;
        value = parseFloat(jQuery(element).html().replace(" ", "").replace("€", ""));
        if ((!inverseTarget && value < newTarget) || (inverseTarget && value > newTarget)) {
          return jQuery(element).removeClass("equal higher blank").addClass("lower");
        } else if (value === newTarget) {
          return jQuery(element).removeClass("lower higher blank").addClass("equal");
        } else if ((!inverseTarget && value > newTarget) || (inverseTarget && value < newTarget)) {
          return jQuery(element).removeClass("lower equal blank").addClass("higher");
        } else {
          return jQuery(element).removeClass("lower equal higher").addClass("blank");
        }
      });
      return jQuery(event.target).parents("form").submit();
    });
    jQuery(this.el).on("submit", "table form", (event) => {
      var method, metricId, path;
      event.preventDefault();
      path = jQuery(event.target).attr("action");
      method = jQuery(event.target).attr("method");
      metricId = jQuery(event.target).find("input[name='statistic_target[metric]']").val();
      return jQuery.ajax(path, {
        type: method,
        data: jQuery(event.target).serializeArray()
      }).done((data, textStatus, jqXHR) => {
        jQuery(event.target).replaceWith(data);
        return this.updateGraphs(metricId);
      });
    });
    jQuery(this.el).on("click", "span.pill", (event) => {
      jQuery(event.target).closest('.graph').find('.page-loader').css('display', 'flex');
      jQuery(event.target).parent().find("span.pill.selected").removeClass("selected");
      jQuery(event.target).addClass("selected");
      return jQuery.get(jQuery(event.target).attr("data-path")).done((data, textStatus, jqXHR) => {
        return jQuery(event.target).parents(".graph").find(".chart").replaceWith(data);
      });
    });
    return jQuery(this.el).on("change", ".metric-selector select", (event) => {
      jQuery(event.target).closest('.graph').find('.page-loader').css('display', 'flex');
      return jQuery.get(jQuery(event.target).val()).done((data, textStatus, jqXHR) => {
        return jQuery(event.target).parents(".graph").find(".chart").replaceWith(data);
      });
    });
  }

  updateGraphs(metricId) {
    jQuery(".pill.selected").each((index, element) => {
      if (jQuery(element).attr("data-metric") === metricId) {
        return jQuery(element).trigger("click");
      }
    });
    return jQuery("select[name='metric']").each((index, element) => {
      // hacky, hacky...
      if (jQuery(element).val() && jQuery(element).val().includes("/" + metricId + "?")) {
        return jQuery(element).trigger("change");
      }
    });
  }

  getSelectedMetrics() {
    var selectedMetrics;
    // TODO: more elegant pls..
    selectedMetrics = [];
    selectedMetrics.push(jQuery(jQuery(".graph")[0]).find(".options .selected").attr("data-metric"));
    selectedMetrics.push(jQuery(jQuery(".graph")[1]).find(".options select").val());
    selectedMetrics.push(jQuery(jQuery(".graph")[2]).find(".options select").val());
    selectedMetrics.push(jQuery(jQuery(".graph")[3]).find(".options .selected").attr("data-metric"));
    return selectedMetrics;
  }

  setSelectedMetrics(selectedMetrics) {
    jQuery(jQuery(".graph")[0]).find(".pill[data-metric=" + selectedMetrics[0] + "]").click();
    jQuery(jQuery(".graph")[1]).find("select").val(selectedMetrics[1]);
    jQuery(jQuery(".graph")[2]).find("select").val(selectedMetrics[2]);
    return jQuery(jQuery(".graph")[3]).find(".pill[data-metric=" + selectedMetrics[3] + "]").click();
  }

};

jQuery(function() {
  if (jQuery(".reporting-dashboard").length > 0) {
    return new ReportingDashboard(jQuery(".reporting-dashboard"));
  }
});
