var Export;

Export = class Export {
  constructor($el) {
    this.$el = $el;
    this.initializeForm();
    this.attachEvents();
  }

  initializeForm() {
    this.$el.find("select.selectize").selectize({
      allowEmptyOption: false,
      create: false
    });
    return this.$el.find(".datepicker").datepicker({
      dateFormat: "yy-mm-dd"
    });
  }

  attachEvents() {}

};

jQuery(function() {
  if (jQuery(".export-filters").length > 0) {
    return new Export(jQuery(".export-filters"));
  }
});
