require('tinymce/themes/modern');

require('tinymce/plugins/link');

$(function() {
  var initTinyMce;
  $(document).on("nested:fieldAdded", function(event) {
    initTinyMce();
    return $("table.job_group .datepicker").not(".hasDatepicker").datepicker();
  });
  $("table.job_group .datepicker").not(".hasDatepicker").datepicker();
  initTinyMce = function() {
    return tinyMCE.init({
      selector: "textarea.tinymce",
      menu: "core",
      toolbar: "formatselect | bold italic bullist numlist | link unlink | undo redo ",
      plugins: "link",
      width: 800,
      height: 300
    });
  };
  return jQuery(function() {
    if (jQuery(".job_group").length > 0) {
      return initTinyMce();
    }
  });
});
