var ApplicableSurcharge;

ApplicableSurcharge = class ApplicableSurcharge {
  constructor($popup) {
    this.updatePopup = this.updatePopup.bind(this);
    this.closePopup = this.closePopup.bind(this);
    this.getForm = this.getForm.bind(this);
    this.checkApplicableSurcharge = this.checkApplicableSurcharge.bind(this);
    this.checkApplicableSurchargeWithCustomEvents = this.checkApplicableSurchargeWithCustomEvents.bind(this);
    this.$popup = $popup;
    this.$popup.find(".selectize").selectize();
    this.completed = false;
  }

  updatePopup(data) {
    this.$popup.find("[name='date']").val(data.assignment.date);
    this.$popup.find("#assignment-times").html(data.assignment.start + " - " + data.assignment.finish);
    this.$popup.find("#assignment-surcharge-duration").html(this.getDurationString(data.surcharge.duration));
    this.$popup.find("[name='assignment_surcharge[start]']").val(data.surcharge.intervals[0].start);
    this.$popup.find("[name='assignment_surcharge[finish]']").val(data.surcharge.intervals[0].finish);
    this.$popup.find("[name='assignment_surcharge[percentage]']")[0].selectize.setValue(data.surcharge.percentage);
    if (data.surcharge.intervals.length > 1) {
      this.$popup.find("[name='assignment_surcharge[second_start]']").val(data.surcharge.intervals[1].start);
      this.$popup.find("[name='assignment_surcharge[second_finish]']").val(data.surcharge.intervals[1].finish);
    }
    return this.$popup.find("a.button.submit").removeClass("disabled");
  }

  closePopup() {
    return $.magnificPopup.close('manual');
  }

  getForm() {
    return this.$popup.find("form");
  }

  getDurationString(surchargeDuration) {
    var duration;
    duration = "";
    if (surchargeDuration.hour === 1) {
      duration += "1 hour ";
    } else if (surchargeDuration.hour > 1) {
      duration += surchargeDuration.hour + " hours ";
    }
    if (surchargeDuration.min === 1) {
      duration += "1 minute ";
    } else if (surchargeDuration.min > 1) {
      duration += surchargeDuration.min + " minutes ";
    }
    return duration.trimRight();
  }

  checkApplicableSurcharge(assignmentId, isBlueprintShift, employeeId, date, startTime, finishTime, callback) {
    return $.get({
      url: this.$popup.attr("data-path").replace("assignmentid", assignmentId),
      dataType: 'json',
      data: {
        is_blueprint_shift: isBlueprintShift,
        employee_id: employeeId,
        date: date,
        start_time: startTime,
        finish_time: finishTime
      },
      success: (response) => {
        if (!$.isEmptyObject(response)) {
          jQuery.magnificPopup.open({
            items: {
              src: "#applicable-surcharge-popup"
            },
            mainClass: "applicable-surcharge-popup",
            callbacks: {
              open: () => {
                return this.updatePopup(response);
              },
              close: () => {
                if (!this.completed) {
                  return document.dispatchEvent(new Event('applicableSurchargeCancelled'));
                }
              }
            }
          });
          this.$popup.unbind();
          this.$popup.on('click', 'a.continue', (event) => {
            this.completed = true;
            this.closePopup();
            return callback();
          });
          return this.$popup.on('click', 'a.submit:not(.disabled)', (event) => {
            var surchargeForm;
            $(event.currentTarget).addClass("disabled");
            surchargeForm = this.getForm();
            return $.post({
              url: surchargeForm.attr('action').replace('assignmentid', assignmentId),
              data: surchargeForm.serialize(),
              success: () => {
                this.completed = true;
                this.closePopup();
                return callback();
              },
              error: (xhr, ajaxOptions, thrownError) => {
                alert("An error occurred while creating the surcharge. Please try to add it manually on the assignment edit page.");
                this.completed = true;
                this.closePopup();
                return callback();
              }
            });
          });
        } else {
          return callback();
        }
      },
      error: (xhr, ajaxOptions, thrownError) => {
        alert("An error occurred attempting to check if a surcharge is applicable. Please add a surcharge manually if it applies to this assignment.");
        return callback();
      }
    });
  }

  checkApplicableSurchargeWithCustomEvents(assignmentId, isBlueprintShift, employeeId, date, startTime, finishTime, callback) {
    return $.get({
      url: this.$popup.attr("data-path").replace("assignmentid", assignmentId),
      dataType: 'json',
      data: {
        is_blueprint_shift: isBlueprintShift,
        employee_id: employeeId,
        date: date,
        start_time: startTime,
        finish_time: finishTime
      },
      success: (response) => {
        if (!$.isEmptyObject(response)) {
          jQuery.magnificPopup.open({
            items: {
              src: "#applicable-surcharge-popup"
            },
            mainClass: "applicable-surcharge-popup",
            callbacks: {
              open: () => {
                return this.updatePopup(response);
              }
            }
          });
        }
        return callback(!$.isEmptyObject(response));
      },
      error: (xhr, ajaxOptions, thrownError) => {
        return callback(false);
      }
    });
  }

};

$(function() {
  if ($("#applicable-surcharge-popup").length > 0) {
    return window.applicableSurcharge = new ApplicableSurcharge($("#applicable-surcharge-popup"));
  }
});
