var SalariesOverview;

SalariesOverview = class SalariesOverview {
  constructor(el) {
    this.el = el;
    $('.selectize').selectize();
    this.table = this.el.find("#hours-and-expenses").DataTable({
      dom: 'Bfrtip',
      pageLength: 200,
      buttons: [
        {
          extend: 'excel',
          text: 'Export to Excel',
          className: 'new green-button excel-button'
        }
      ]
    });
  }

};

$(function() {
  var overview;
  overview = jQuery.find("#salaries-overview");
  if (overview.length > 0) {
    return new SalariesOverview(jQuery(overview));
  }
});
