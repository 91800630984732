var EmployeeContract;

EmployeeContract = class EmployeeContract {
  constructor(element, type) {
    this.attachHandlers = this.attachHandlers.bind(this);
    this.element = element;
    this.fulltimeHoursPerDay = this.element.data("hours-per-day");
    this.fulltimeDaysPerWeek = this.element.data("days-per-week");
    this.fulltimeHoursPerMonth = this.element.data("hours-per-month");
    this.attachHandlers();
    this.updateProposedHours();
    this.updateHoursPerPeriod();
    this.updatePeriodicSalary();
  }

  attachHandlers() {
    this.element.find("input#employee_contract_hours").keyup(() => {
      this.updateProposedHours();
      return this.updateHoursPerPeriod();
    });
    this.element.find("input#employee_contract_days_per_week").keyup(() => {
      return this.updateProposedHours();
    });
    this.element.find("input#employee_contract_salary_monthly").keyup(() => {
      return this.updatePeriodicSalary();
    });
    return this.element.find("a#proposed").on("click", (event) => {
      return this.element.find("input#employee_contract_hours_per_day").val(event.target.title);
    });
  }

  updateProposedHours() {
    var daysPerWeek, hoursPerDay, hoursPerMonth, proposedHoursPerDay, proposedHoursPerDayInfo;
    hoursPerDay = this.element.find("input#employee_contract_hours_per_day").val();
    hoursPerMonth = this.element.find("input#employee_contract_hours").val();
    daysPerWeek = this.element.find("input#employee_contract_days_per_week").val();
    proposedHoursPerDay = Math.round(10 * this.fulltimeHoursPerDay * hoursPerMonth / this.fulltimeHoursPerMonth * this.fulltimeDaysPerWeek / daysPerWeek) / 10;
    proposedHoursPerDayInfo = "[proposed: " + proposedHoursPerDay + " hours]";
    return this.element.find("a#proposed").html(proposedHoursPerDayInfo).attr("title", proposedHoursPerDay);
  }

  updateHoursPerPeriod() {
    var hoursPerMonth, hoursPerPeriodInfo;
    hoursPerMonth = this.element.find("input#employee_contract_hours").val();
    hoursPerPeriodInfo = "" + Math.round(hoursPerMonth * 12 / 13) + " hours per period";
    return this.element.find("span#hours_per_period").html(hoursPerPeriodInfo);
  }

  updatePeriodicSalary() {
    var monthlySalary, periodicSalary;
    monthlySalary = this.element.find("input#employee_contract_salary_monthly").val();
    monthlySalary = Math.round(monthlySalary * 12 / 13);
    if (monthlySalary > 0) {
      periodicSalary = "&euro; " + monthlySalary + " per period";
      return this.element.find("span#periodic_salary").html(periodicSalary);
    } else {
      return this.element.find("span#periodic_salary").html("");
    }
  }

};

if ($("form.edit_employee_contract").length) {
  new EmployeeContract($("form.edit_employee_contract"), "edit");
} else if ($("form.new_employee_contract").length) {
  new EmployeeContract($("form.new_employee_contract"), "new");
}
