var EditableTimeRangeController;

EditableTimeRangeController = class EditableTimeRangeController {
  constructor(el) {
    this.attachEventHandlers = this.attachEventHandlers.bind(this);
    this.el = el;
    this.attachEventHandlers();
  }

  attachEventHandlers() {
    this.el.on('click focusin', '.editable-time-range input', (e) => {
      return $(e.currentTarget).select();
    });
    this.el.on('input', '.editable-time-range input', (e) => {
      if (isNaN($(e.currentTarget).val())) {
        $(e.currentTarget).val('');
      }
      if ($(e.currentTarget).val().length >= 2) {
        return $(e.currentTarget).nextAll('input').first().focus();
      }
    });
    return this.el.on('focusout', '.editable-time-range input', (e) => {
      // Ensure that the time input can never exceed two characters
      return $(e.currentTarget).val(`00${$(e.currentTarget).val()}`.slice(-2));
    });
  }

};

$(function() {
  if ($('.invoicing-overview').length) {
    return new EditableTimeRangeController($('.invoicing-overview'));
  }
});
