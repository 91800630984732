var CompanyOverview;

window.CompanyOverview = CompanyOverview = class CompanyOverview {
  constructor(el) {
    this.initFilters = this.initFilters.bind(this);
    this.attachEvents = this.attachEvents.bind(this);
    this.attachDetailEvents = this.attachDetailEvents.bind(this);
    this.fetchCompaniesByFilter = this.fetchCompaniesByFilter.bind(this);
    this.fetchCompanyDetails = this.fetchCompanyDetails.bind(this);
    this.loading = this.loading.bind(this);
    this.el = el;
    this.filters = this.el.find("select.selectize");
    this.table = this.el.find("#company-results");
    this.initFilters();
    this.attachDetailEvents();
    this.attachEvents();
  }

  initFilters() {
    this.filters.each((index, field) => {
      return jQuery(field).selectize({
        create: false,
        onChange: () => {
          return this.fetchCompaniesByFilter();
        }
      });
    });
    return jQuery('#search').keyup(jQuery.debounce(500, this.fetchCompaniesByFilter));
  }

  attachEvents() {
    if (jQuery('tr.main').length === 1) {
      this.toggleCompanyRow($('tr.main'));
    }
    return jQuery('tr.main').on('click', (e) => {
      if (!$(e.target).is('a')) {
        return this.toggleCompanyRow($(e.target.closest('tr.main')));
      }
    });
  }

  attachDetailEvents() {
    jQuery('#company-results').on('click', '.assignments-button', (e) => {
      return this.fetchTableData(e, 'assignments');
    });
    jQuery('#company-results').on('click', '.contacts-button', (e) => {
      return this.fetchTableData(e, 'contacts');
    });
    jQuery('#company-results').on('click', '.venues-button', (e) => {
      return this.fetchTableData(e, 'venues');
    });
    jQuery('#company-results').bind('ajax:complete', '#new_note', (event, data) => {
      $('tbody.notes-list').prepend(data.responseText);
      return $('.new-note-field').val('');
    });
    jQuery('#company-results').on('click', '.save-note', function(e) {
      var row;
      return row = $(e.target).parent().parent().find('form').submit();
    });
    return jQuery('#company-results').on('click', '.remove-note', function(e) {
      $(e.target).parent().submit();
      return $(e.target).closest('tr').hide();
    });
  }

  fetchTableData(e, type) {
    $(e.target).addClass('loading');
    return $.get(`/admin/crm/companies/${$(e.target).data('id')}/${type}`, (data) => {
      $(`#${type}-container .content`).html(data);
      $(e.target).removeClass('loading');
      return jQuery.magnificPopup.open({
        items: {
          src: `#${type}-container`
        }
      });
    });
  }

  toggleCompanyRow(row) {
    var detailsRow, opened;
    opened = row.hasClass('open');
    detailsRow = $("#" + row.attr('id') + "-details");
    $('tr.details').hide();
    $('tr.main').removeClass('open');
    if (!opened) {
      if (detailsRow.find('.details-content').length > 0) {
        return this.fetchCompanyDetails(row, detailsRow);
      } else {
        row.addClass('open');
        return detailsRow.show();
      }
    }
  }

  fetchCompaniesByFilter() {
    this.loading(true);
    return $.get('/admin/crm/companies', {
      filter: 'true',
      search: this.el.find('#search').val()
    }, (data) => {
      this.table.html(data);
      window.history.replaceState({}, '', '/admin/crm/companies');
      this.loading(false);
      return this.attachEvents();
    });
  }

  fetchCompanyDetails(row, detailsRow) {
    row.addClass('loading');
    return $.get(`/admin/crm/companies/${row.data('id')}/details`, (data) => {
      detailsRow.find('.details-content').replaceWith(data);
      row.removeClass('loading');
      row.addClass('open');
      return detailsRow.show();
    });
  }

  loading(state) {
    if (state) {
      return this.el.find('.double-spinner').show();
    } else {
      return this.el.find('.double-spinner').hide();
    }
  }

};

$(function() {
  var overview;
  overview = jQuery.find('#company-overview');
  if (overview.length > 0) {
    new CompanyOverview(jQuery(overview));
  }
  if (jQuery("select[name='company[initialcontactid]']").length > 0) {
    return jQuery('.selectize').selectize();
  }
});
