$(document).ready(function () {
  jQuery("td.notes").delegate("a", "click", function(event) {
    var cell = $(this).closest("td")
    cell.find("div:first-child").css("display", "none")
    cell.find("div:last-child").css("display", "block")
    return false
  })
  jQuery("td.notes").delegate("button", "click", function(event) {
    var cell = $(this).closest("td")
    cell.load("/admin/extranet.php?o=invoice&a=unpaidindex_notes", {
      id: cell.attr("data-id"),
      notes: cell.find("textarea").val()
    })
    return false
  })
})
