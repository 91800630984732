// Tooltips
var tipEnabled
var offsetX = -2
var offsetY = 19
var ie = document.all
var w3c = document.getElementById && !document.all
var theTooltip

//show the tooltip
window.showTip = function showTip(id) {
  if (w3c) theTooltip = document.getElementById(id)
  if (ie) theTooltip = document.all[id]
  if (document.getElementById(id)) theTooltip = document.getElementById(id) // Take this if it works (does for ie6, while all[id] doesn't) Grr!
  if (w3c || ie) {
    tipEnabled = true
    return false
  }
}

// Used in the main Finance page.
window.showSmallHoursTooltip = function showSmallHoursToolTip(plannedWorkHours, claimedHours, travelHours) {
  if (w3c) theTooltip = document.getElementById("hours_tooltip")
  if (ie) theTooltip = document.all["hours_tooltip"]
  if (document.getElementById("hours_tooltip"))
    theTooltip = document.getElementById("hours_tooltip") // Take this if it works (does for ie6, while all[id] doesn't) Grr!

  var theTooltipText
  if (w3c) theTooltipText = document.getElementById("hours_tooltip")
  if (ie) theTooltipText = document.all["hours_tooltip"]

  theTooltipText.innerHTML = "<table width='100%'><tr><td>Planned work hours:</td><td align='right'>"
    .concat(plannedWorkHours)
    .concat("</td></tr><tr><td>Claimed work hours:</td><td align='right'>")
    .concat(claimedHours)
    .concat("</td></tr><tr><td>Claimed travel hours:</td><td align='right'>")
    .concat(travelHours)
    .concat("</td></tr></table>")

  if (w3c || ie) {
    tipEnabled = true
    return false
  }
}

//hides tooltip
window.hideTip = function hideTip() {
  if ((w3c || ie) && undefined !== theTooltip) {
    theTooltip.style.visibility = "hidden"
    tipEnabled = false
  }
}

//moves tooltip to cursor
window.repositionTip = function repositionTip(e) {
  if (tipEnabled) {
    var x = e.clientX,
      y = e.clientY
    theTooltip.style.top = y - 60 + window.scrollY + "px"
    theTooltip.style.left = x - 130 + window.scrollX + "px"
    theTooltip.style.visibility = "visible"
  }
}

//set tooltip contents
window.setInfo = function setInfo(text) {
  if (ie) var infoField = document.all["info"]
  if (w3c) var infoField = document.getElementById("info")
  infoField.innerHTML = text
}

$(document).ready(function () {
  document.onmousemove = repositionTip
})
