var UserOverview;

UserOverview = class UserOverview {
  constructor(el) {
    this.attachEvents = this.attachEvents.bind(this);
    this.attachDetailEvents = this.attachDetailEvents.bind(this);
    this.fetchUserDetails = this.fetchUserDetails.bind(this);
    this.loading = this.loading.bind(this);
    this.el = el;
    this.table = this.el.find("#users-results");
    this.attachDetailEvents();
    this.attachEvents();
    this.attachHandlers();
  }

  attachEvents() {
    return jQuery('tr.main').on('click', (e) => {
      // Prevent toggle if the clicked element is a link
      if (e.target.nodeName !== 'A') {
        e.preventDefault();
        return this.toggleUserRow($(e.target.closest('tr.main')));
      }
    });
  }

  attachDetailEvents() {
    jQuery('#users-results').on('change', '.edit_user input', function(e) {
      return $(e.target).closest('form').submit();
    });
    jQuery('#users-results').on('click', (e) => {
      return this.fetchTableData(e, 'user');
    });
    return jQuery('#users-results').on('click', '.contracts-button', (e) => {
      return this.fetchTableData(e, 'contract');
    });
  }

  attachHandlers() {
    return this.el.on("click", "input[type='checkbox'][name='business_unit_restriction']", (event) => {
      return this.toggle(event);
    });
  }

  toggle(event) {
    var business_unit_id, input, user_id;
    input = jQuery(event.target);
    user_id = input.data("user-id");
    business_unit_id = input.data("business-unit-id");
    return jQuery.post(`/admin/user_management/user_business_unit_restrictions/${user_id}/${business_unit_id}/toggle.json`, (data) => {
      return input.prop("checked", data.exists);
    });
  }

  fetchTableData(e, type) {
    $(e.target).addClass('loading');
    return $.get(`/admin/user_management/users/${$(e.target).data('id')}`, (data) => {
      $(`#${type}-container .content`).html(data);
      return $(e.target).removeClass('loading');
    });
  }

  toggleUserRow(row) {
    var detailsRow, opened;
    opened = row.hasClass('open');
    detailsRow = $("#" + row.attr('id') + "-details");
    $('tr.details').hide();
    $('tr.main').removeClass('open');
    if (!opened) {
      if (detailsRow.find('.details-content').length > 0) {
        return this.fetchUserDetails(row, detailsRow);
      } else {
        row.addClass('open');
        return detailsRow.show();
      }
    }
  }

  fetchUserDetails(row, detailsRow) {
    detailsRow.find('.details-content');
    row.removeClass('loading');
    row.addClass('open');
    return detailsRow.show();
  }

  loading(state) {
    if (state) {
      return this.el.find('.double-spinner').show();
    } else {
      return this.el.find('.double-spinner').hide();
    }
  }

};

$(function() {
  var overview;
  overview = jQuery.find('#user-overview');
  if (overview.length > 0) {
    return new UserOverview(jQuery(overview));
  }
});
