// JavaScript Document

window.toggleDateField = function toggleDateField(id){
    fields = getDateFields(id);
    var bool = fields['y'].disabled;
    fields['y'].disabled = !bool;
    fields['m'].disabled = !bool;
    fields['d'].disabled = !bool;
}

window.toggleDateVisible = function toggleDateVisible(field, id) {
    if (field.checked) {
        document.getElementById(id).style.visibility = 'visible';
    } else {
        document.getElementById(id).style.visibility = 'hidden';
    }
}

window.updateDateField = function updateDateField(id){
    fields = getDateFields(id);
    fields['date'].value = fields['y'].value + '-' + fields['m'].value + '-' + fields['d'].value;
}

window.getDateFields = function getDateFields(id){
    var fields = new Array();
    fields['date'] = document.getElementById(id);
    fields['d'] = document.getElementById(id+'D');
    fields['m'] = document.getElementById(id+'M');
    fields['y'] = document.getElementById(id+'Y');
    return fields;
}

$(document).ready(function(){
    $("form.invoice-credit-form").on("submit", function() {
        $(this).find("input").attr("disabled", "disabled")
    })
})