var InvoicingOverviewContact;

window.InvoicingOverviewContact = InvoicingOverviewContact = class InvoicingOverviewContact {
  constructor(row1, overview) {
    this.attachEventHandlers = this.attachEventHandlers.bind(this);
    this.initializeNewRow = this.initializeNewRow.bind(this);
    this.fetchAssignmentData = this.fetchAssignmentData.bind(this);
    this.renderAssignmentData = this.renderAssignmentData.bind(this);
    this.initializeContent = this.initializeContent.bind(this);
    this.handleError = this.handleError.bind(this);
    this.scrollToRow = this.scrollToRow.bind(this);
    this.uninvoicedAssignments = this.uninvoicedAssignments.bind(this);
    this.selectedAssignments = this.selectedAssignments.bind(this);
    this.selectedAssignmentIds = this.selectedAssignmentIds.bind(this);
    this.selectedApprovedAssignments = this.selectedApprovedAssignments.bind(this);
    this.selectedApprovedAssignmentIds = this.selectedApprovedAssignmentIds.bind(this);
    this.selectedApproveableAssignments = this.selectedApproveableAssignments.bind(this);
    this.selectedApproveableAssignmentIds = this.selectedApproveableAssignmentIds.bind(this);
    this.setDateBorders = this.setDateBorders.bind(this);
    this.isLastOnDate = this.isLastOnDate.bind(this);
    this.updateSelectedStatus = this.updateSelectedStatus.bind(this);
    this.submitBulkAction = this.submitBulkAction.bind(this);
    this.refresh = this.refresh.bind(this);
    this.updateBulkOptions = this.updateBulkOptions.bind(this);
    this.updateBulkButtons = this.updateBulkButtons.bind(this);
    this.updateTotals = this.updateTotals.bind(this);
    this.exceptionMessage = this.exceptionMessage.bind(this);
    this.row = row1;
    this.overview = overview;
    this.row.addClass('loading');
    this.assignments = [];
    this.selectedLabel = null;
    this.fetchAssignmentData();
  }

  attachEventHandlers() {
    this.exceptionMessage(false);
    this.row.find('.contract-details-button').on('click', (e) => {
      return $(e.currentTarget).toggleClass('opened');
    });
    this.table.find('.select-all-assignments').on('click', (e) => {
      this.table.find('.select-assignment').prop('checked', $(e.currentTarget).prop('checked'));
      return this.updateSelectedStatus();
    });
    this.table.find('.bulk-action a.send-invoice').magnificPopup({
      type: 'ajax',
      callbacks: {
        open: () => {
          if (this.table.find(".totals-row .send-invoice").hasClass("disabled")) {
            return $.magnificPopup.instance.close();
          }
        },
        ajaxContentAdded: () => {
          return new InvoicePreview($('.invoice-preview'), this);
        }
      }
    });
    this.table.find('.bulk-action a:not(.send-invoice)').on('click', (e) => {
      e.preventDefault();
      if (!$(e.currentTarget).hasClass('disabled')) {
        return this.submitBulkAction($(e.currentTarget));
      }
    });
    this.table.on('click', 'span[data-type]', (event) => {
      var assignment, j, k, len, len1, ref, ref1, row, type, value;
      row = $(event.currentTarget).parents("tr");
      type = $(event.currentTarget).attr("data-type");
      value = $(event.currentTarget).html();
      if (row.hasClass("invoiced")) {
        return;
      }
      if (this.selectedLabel === type) {
        ref = this.uninvoicedAssignments();
        for (j = 0, len = ref.length; j < len; j++) {
          assignment = ref[j];
          assignment.setState(false);
        }
        this.selectedLabel = null;
      } else {
        ref1 = this.uninvoicedAssignments();
        for (k = 0, len1 = ref1.length; k < len1; k++) {
          assignment = ref1[k];
          assignment.setState(assignment.getValue(type) === value);
        }
        this.selectedLabel = type;
      }
      return this.updateSelectedStatus();
    });
    return this.row.find('.refresh-row').on('click', (e) => {
      $(e.currentTarget).addClass('loading');
      return this.refresh();
    });
  }

  initializeNewRow(data) {
    this.selectedAssignmentIdsBeforeRender = this.selectedAssignmentIds();
    this.row.replaceWith(data);
    this.row = $(`.contact-container[data-id='${this.row.data('id')}']`);
    jQuery.magnificPopup.close();
    return this.initializeContent();
  }

  fetchAssignmentData() {
    return $.ajax({
      url: `/admin/finance/invoicing/overview/${this.row.data('id')}?partial=assignments_table&year=${this.overview.currentYear}&month=${this.overview.currentMonth}`,
      method: 'GET',
      error: () => {
        return this.renderAssignmentData(false);
      },
      success: (data) => {
        return this.renderAssignmentData(true, data);
      }
    });
  }

  renderAssignmentData(succeeded, data = '') {
    this.selectedAssignmentIdsBeforeRender = this.selectedAssignmentIds();
    this.row.find('.assignment-container').html(data);
    if (succeeded) {
      this.initializeContent();
      this.row.addClass('fetched');
    } else {
      this.exceptionMessage(true);
    }
    this.overview.collapseAllRows();
    this.row.removeClass('loading collapsed');
    return this.scrollToRow();
  }

  initializeContent() {
    this.table = this.row.find('table.assignments');
    this.assignments = this.table.find('tr.assignment').map((i, row) => {
      return new InvoicingOverviewAssignment($(row), this);
    });
    this.attachEventHandlers();
    this.updateSelectedStatus();
    return this.setDateBorders();
  }

  handleError() {
    this.exceptionMessage(true, true);
    this.scrollToRow();
    return setTimeout((() => {
      return this.fetchAssignmentData();
    }), 2000);
  }

  scrollToRow() {
    this.row[0].scrollIntoView();
    return window.scrollBy(0, -30); // px
  }

  uninvoicedAssignments() {
    return this.assignments.filter((i, assignment) => {
      return !assignment.isInvoiced();
    });
  }

  selectedAssignments(type) {
    return this.uninvoicedAssignments().filter((i, assignment) => {
      return assignment.isSelected() && (!type || assignment.getType() === type);
    });
  }

  selectedAssignmentIds() {
    var assignment, j, len, ref, results;
    ref = this.selectedAssignments();
    results = [];
    for (j = 0, len = ref.length; j < len; j++) {
      assignment = ref[j];
      results.push(assignment.getId());
    }
    return results;
  }

  selectedApprovedAssignments() {
    return this.uninvoicedAssignments().filter((i, assignment) => {
      return assignment.isSelected() && assignment.isApproved();
    });
  }

  selectedApprovedAssignmentIds() {
    var assignment, j, len, ref, results;
    ref = this.selectedApprovedAssignments();
    results = [];
    for (j = 0, len = ref.length; j < len; j++) {
      assignment = ref[j];
      results.push(assignment.getId());
    }
    return results;
  }

  selectedApproveableAssignments() {
    return this.uninvoicedAssignments().filter((i, assignment) => {
      return assignment.isSelected() && assignment.canBeApproved();
    });
  }

  selectedApproveableAssignmentIds() {
    var assignment, j, len, ref, results;
    ref = this.selectedApproveableAssignments();
    results = [];
    for (j = 0, len = ref.length; j < len; j++) {
      assignment = ref[j];
      results.push(assignment.getId());
    }
    return results;
  }

  setDateBorders() {
    return this.assignments.each((i, assignment) => {
      return assignment.setDateBorder();
    });
  }

  isLastOnDate(assignment) {
    var next_assignment;
    if (next_assignment = this.assignments[this.assignments.index(assignment) + 1]) {
      return next_assignment.date !== assignment.date;
    }
  }

  updateSelectedStatus() {
    this.uninvoicedAssignments().each((i, assignment) => {
      return assignment.updateSelectedStatus();
    });
    this.table.find('.select-all-assignments').prop('checked', !this.table.find('.select-assignment:not(:checked)').length);
    this.updateBulkOptions();
    return this.updateTotals();
  }

  submitBulkAction(target) {
    var assignments;
    this.table.find('td.with-spinner .double-spinner').show();
    if (target.hasClass('requires-approval')) {
      assignments = this.selectedApprovedAssignmentIds();
    } else if (target.hasClass('approve-rows')) {
      assignments = this.selectedApproveableAssignmentIds();
    } else {
      assignments = this.selectedAssignmentIds();
    }
    return $.ajax({
      url: target.data('link') + `?assignment_ids=${assignments.join(',')}`,
      method: target.data('request'),
      data: {
        assignments: assignments.map((assignment) => {
          return Object.assign({
            id: assignment
          }, target.data('parameters'));
        })
      },
      success: () => {
        return this.refresh();
      },
      error: () => {
        return this.handleError();
      }
    });
  }

  refresh() {
    return this.overview.refreshRow(this);
  }

  updateBulkOptions() {
    var previewUrl;
    this.updateBulkButtons();
    if (this.selectedApprovedAssignmentIds().length) {
      previewUrl = this.table.find(".totals-row .send-invoice").attr("href");
      if (!previewUrl) {
        return;
      }
      if (previewUrl.includes("assignment_ids=")) {
        previewUrl = previewUrl.substring(0, previewUrl.indexOf("&assignment_ids="));
      }
      previewUrl += "&assignment_ids=" + this.selectedApprovedAssignmentIds().join(",");
      return this.table.find(".totals-row .send-invoice").attr("href", previewUrl);
    }
  }

  updateBulkButtons() {
    if (this.selectedAssignmentIds().length) {
      this.table.find('.bulk-action a').removeClass('disabled');
      if (!this.selectedApprovedAssignmentIds().length) {
        return this.table.find(".bulk-action a.requires-approval").addClass('disabled');
      }
    } else {
      return this.table.find('.bulk-action a').addClass('disabled');
    }
  }

  updateTotals() {
    var assignment, hours, j, len, ref, subTotal, surchargeAmount, totalAmount, vat;
    hours = surchargeAmount = subTotal = vat = totalAmount = 0;
    ref = this.selectedAssignments();
    for (j = 0, len = ref.length; j < len; j++) {
      assignment = ref[j];
      subTotal += assignment.getAmount("amount");
      vat += assignment.getAmount("vat");
      totalAmount += assignment.getAmount("total");
      hours += assignment.getHours("hours");
      surchargeAmount += assignment.getAmount("surcharge");
    }
    this.table.find("tr.totals-row span[data-type='subtotal']").html("€" + subTotal.toFixed(2));
    this.table.find("tr.totals-row span[data-type='vat']").html("€" + vat.toFixed(2));
    this.table.find("tr.totals-row span[data-type='total']").html("€" + totalAmount.toFixed(2));
    this.table.find("tr.totals-row td[data-type='hours']").html(this.readableHours(hours));
    this.table.find("tr.totals-row td[data-type='surcharge']").html("€" + surchargeAmount.toFixed(2));
    return this.table.find("tr.totals-row td[data-type='total']").html("€" + subTotal.toFixed(2));
  }

  readableHours(fraction) {
    var hours, minutes;
    // TODO: make this a helper function instead of scoped to this class..
    hours = Math.floor(fraction);
    minutes = ((fraction % 1) * 60).toFixed(0);
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    return hours + ":" + minutes;
  }

  exceptionMessage(show = true, reloading = false) {
    if (show) {
      this.row.find('.assignment-container').html('');
      this.row.find('.exception-message').css('display', 'inline-block');
      if (reloading) {
        return this.row.find('.reloading').show();
      } else {
        return this.row.find('.reloading').hide();
      }
    } else {
      return this.row.find('.exception-message').hide();
    }
  }

};
