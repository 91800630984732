var ContactSearch;

ContactSearch = class ContactSearch {
  constructor(element, onContactChange) {
    this.onSearch = this.onSearch.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onKeyPress = this.onKeyPress.bind(this);
    this.chooseContact = this.chooseContact.bind(this);
    this.setContact = this.setContact.bind(this);
    this.element = element;
    this.onContactChange = onContactChange;
    this.contactSearch = $(this.element).find("#contact_search");
    this.changeLink = $(this.element).find("> a.action");
    this.searchLink = $(this.element).find("#contact_search a.action");
    this.form = $(this.element).closest("form");
    if ($(this.element).find("> span").is(":empty")) {
      this.initNew();
    } else {
      this.initEdit();
    }
    this.searchLink.click(this.onSearch);
    this.changeLink.click(this.onChange);
    this.form.submit(this.onSubmit);
    $(this.element).find("#contact_name").keypress(this.onKeyPress);
  }

  initNew() {
    this.hideChangeLink();
    return $("#venues_select").hide(); // TODO: move to assignment_blueprints coffeescript.
  }

  initEdit() {
    this.showChangeLink();
    this.contactSearch.hide();
    if ($("#venues_select").is(":empty")) { // TODO: move to assignment_blueprints coffeescript.
      return $("#venues_select").hide();
    }
  }

  onSearch(event) {
    var name, target;
    name = $(this.element).find("input[name='contact_name']").val();
    target = $(this.searchLink).data("target");
    $.get(`${target}?name=${name}`, (data) => {
      return this.update(data);
    });
    return false;
  }

  onChange(event) {
    this.contactSearch.show();
    return false;
  }

  onSubmit(event) {
    if (!$("#contact_id").val() && !$("#company_id").val()) {
      alert("Please select a contact.");
      return false;
    }
    return true;
  }

  onKeyPress(event) {
    if (event.which === 13) {
      this.searchLink.trigger("click");
      return false;
    }
  }

  showChangeLink() {
    return $(this.element).find("> a").show();
  }

  hideChangeLink() {
    return $(this.element).find("> a").hide();
  }

  update(data) {
    $(this.element).find("#contact_search_results").html(data);
    return $(this.element).find("#contact_search_results a.action").click(this.chooseContact);
  }

  chooseContact(event) {
    var company, company_id, id, name, tableData, td;
    id = $(event.target).data("contact-id");
    company_id = $(event.target).data("company-id");
    tableData = $(event.target).closest("tr").children();
    [name, company] = (function() {
      var i, len, ref, results;
      ref = tableData.slice(0, 2);
      results = [];
      for (i = 0, len = ref.length; i < len; i++) {
        td = ref[i];
        results.push($(td).html());
      }
      return results;
    })();
    this.setCompany(company_id, company);
    this.setContact(id, name);
    return this.onContactChange(event);
  }

  setCompany(id, name) {
    $("#company").html(name);
    if ($("#company_id").length > 0) {
      return $("#company_id").val(id);
    }
  }

  setContact(id, name) {
    $(this.element).find("> span").html(name + "&nbsp;");
    $("#contact_id").val(id);
    this.showChangeLink();
    return this.contactSearch.hide();
  }

};

$(function() {
  if (document.getElementById("teams_contact")) {
    new ContactSearch($("#teams_contact"), function(event) {
      return {};
    });
  }
  $('.admin.form').find("select.selectize").selectize({
    create: false,
    sortField: "text"
  });
  return $(document).on("nested:fieldAdded", function(event) {
    return $('.admin.form').find("select.selectize").selectize({
      create: false,
      sortField: "text"
    });
  });
});
