$(function() {
  $("form.auto_submit input[type=radio]").click(function() {
    return $(this).parents("form").submit();
  });
  $("form.auto_submit input[type=checkbox]").click(function() {
    return $(this).parents("form").submit();
  });
  return $("form.auto_submit select").change(function() {
    return $(this).parents("form").submit();
  });
});
