function isNumber(mynumber)
{
    if (mynumber == parseInt(mynumber)) return true;
    return false;
}


function isFloat(mynumber)
{
    if (parseFloat(mynumber) > 0) return true;
    return false;
}

function isHour(mynumber)
{
    if (!isNumber(mynumber)) return false;
    if ((mynumber >= 0) && (mynumber <= 24)) return true;
    return false;
}

function isMinute(mynumber)
{
    if (!isNumber(mynumber)) return false;
    if ((mynumber >= 0) && (mynumber <= 59)) return true;
    return false;
}

function isTime(hour, minute)
{
    if (!isHour(hour)) return false;
    if (!isMinute(minute)) return false;
    if ((hour == 24) && (minute != 0)) return false;
    return true;
}

function isPercentage(mynumber)
{
    mynumber = mynumber.replace(",", ".");

    if (!isFloat(mynumber)) return false;
    if (mynumber >= 0) return true;
    return false;
}

function timeIsSmaller(start_hour, start_minute, end_hour, end_minute)
{
    if (start_hour < end_hour) return true;
    if ((start_hour == end_hour) && (start_minute < end_minute)) return true;
    return false;
}

function validateSurcharge(form)
{
    start_hour     = form.find("input#surcharge_start_time_0").val();
    start_minute   = form.find("input#surcharge_start_time_1").val();
    end_hour       = form.find("input#surcharge_end_time_0").val();
    end_minute     = form.find("input#surcharge_end_time_1").val();
    percentage     = form.find("input#surcharge_percentage").val();

    if (!isTime(start_hour, start_minute)) {
        alert("Start time is not valid. Please provide a time between 00:00 and 24:00.");
        return false;
    }

    if (!isTime(end_hour, end_minute)) {
        alert("End time is not valid. Please provide a time between 00:00 and 24:00.");
        return false;
    }

    if(!timeIsSmaller(start_hour, start_minute, end_hour, end_minute)) {
        alert("Start time must be before end time.");
        return false;
    }

    if (!isPercentage(percentage)) {
        alert("Percentage is not valid");
        return false;
    }
    return true;
}

$(function(){
    $("table.jobgroup input.add").click(function(){
        form = $(this).parent().parent();
        if (!validateSurcharge(form)) return false;
        data = {
            "surcharge[job_groupId]": $("input#job_groupId").val(),
            "surcharge[day]": form.find("select#surcharge_day").val(),
            "surcharge[start_time][0]":  form.find("input#surcharge_start_time_0").val(),
            "surcharge[start_time][1]":  form.find("input#surcharge_start_time_1").val(),
            "surcharge[end_time][0]":  form.find("input#surcharge_end_time_0").val(),
            "surcharge[end_time][1]":  form.find("input#surcharge_end_time_1").val(),
            "surcharge[percentage]":  form.find("input#surcharge_percentage").val()
        };
        $.post("?o=ajax_surcharge&a=add", data, function(data){
          $("table.jobgroup tr.edit").before(data);
          installDeleteHandlers();
          $("tr.nosurcharges").hide();
        });
        return false;
    })

    installDeleteHandlers();
})

function installDeleteHandlers(){
    $("table.jobgroup input.delete").click(function(){
        surchargeId = $(this).parent().parent().find("input.surcharge_id").val();
        row = $(this).parent().parent();
        data = {
            "surcharge[surchargeId]": surchargeId
        }
        $.post("?o=ajax_surcharge&a=delete", data, function(data){
            row.hide()
        })


        return false;
    })
}