var GrossMarginReporting;

window.monthSelectPlugin = require('flatpickr/dist/plugins/monthSelect');

window.confirmDatePlugin = require('flatpickr/dist/plugins/confirmDate/confirmDate');

GrossMarginReporting = class GrossMarginReporting {
  constructor(overview) {
    this.attachHandlers = this.attachHandlers.bind(this);
    this.toggleFinanceDetails = this.toggleFinanceDetails.bind(this);
    this.fetchAssignmentData = this.fetchAssignmentData.bind(this);
    this.monthPicker = this.monthPicker.bind(this);
    this.renderFinancialData = this.renderFinancialData.bind(this);
    this.scrollToRow = this.scrollToRow.bind(this);
    this.updateData = this.updateData.bind(this);
    this.exceptionMessage = this.exceptionMessage.bind(this);
    this.collapseAllRows = this.collapseAllRows.bind(this);
    this.overview = overview;
    this.attachHandlers();
    this.openedRows = {};
    this.params = new URLSearchParams((new URL(window.location.href)).search);
    this.params.set("sort", "gross_margin");
  }

  attachHandlers() {
    jQuery('body').on('click', '.assignments-button', (e) => {
      e.stopPropagation();
      return this.fetchTableData(e, 'assignment');
    });
    jQuery(this.overview).on("click", ".header-item", (event) => {
      var searchParams;
      event.preventDefault();
      searchParams = new URL(event.currentTarget.href).searchParams;
      if (searchParams.get("sort") !== this.params.get("sort")) {
        this.params.set("sort", searchParams.get("sort"));
        this.params.set("order", "asc");
      } else {
        this.params.set("order", searchParams.get("order"));
      }
      return this.updateData();
    });
    jQuery(this.overview).on("click", ".income-expand, .costs-expand", (event) => {
      var container;
      container = jQuery(event.target).closest(".income, .costs");
      container.toggleClass("collapsed");
      if (!container.hasClass("collapsed")) {
        return container.parents(".customer")[0].scrollIntoView(true);
      }
    });
    jQuery(this.overview).on("click", ".toggle-box > div", (event) => {
      var container;
      container = jQuery(event.currentTarget).closest(".chart-anchor");
      $(container.find('.toggle-box > div')).removeClass('active');
      jQuery(event.currentTarget).addClass('active');
      $(container.find('.gross-margin-chart')).removeClass('active');
      return $(jQuery.find(`#${jQuery(event.currentTarget).data('id')}`)).addClass('active');
    });
    jQuery(this.overview).on("click", "tr.employee a", (event) => {
      return event.stopPropagation();
    });
    jQuery(this.overview).on("click", "tr.employee", (event) => {
      if (!jQuery(event.target).hasClass('assignments-button')) {
        jQuery(event.currentTarget).toggleClass("collapsed");
        return jQuery(event.currentTarget).nextUntil(".employee, .totals").toggle();
      }
    });
    return this.overview.on("click", ".contact-information", (e) => {
      if (!$(e.target).is("a i")) {
        return this.toggleFinanceDetails($(e.currentTarget).parent());
      }
    });
  }

  fetchTableData(e, type) {
    var companyId, end_month, end_year, start_month, start_year;
    $(e.target).addClass('loading');
    companyId = $(e.target).data('company-id');
    start_year = $(e.target).data('start-year');
    end_year = $(e.target).data('end-year');
    start_month = $(e.target).data('start-month');
    end_month = $(e.target).data('end-month');
    return $.get(`/admin/hrm/employees/${$(e.target).data('id')}/employee_${type}s?company_id=${companyId}&start_year=${start_year}&end_year=${end_year}&start_month=${start_month}&end_month=${end_month}`, (data) => {
      $(`#${type}-container .content`).html(data);
      $(e.target).removeClass('loading');
      return jQuery.magnificPopup.open({
        items: {
          src: `#${type}-container`
        }
      });
    });
  }

  toggleFinanceDetails(row) {
    if (row.hasClass("collapsed")) {
      if (row.hasClass("fetched")) {
        row.removeClass("collapsed");
        return this.scrollToRow(row);
      } else {
        jQuery(row).addClass('loading');
        return this.fetchAssignmentData(row);
      }
    } else {
      return row.addClass("collapsed");
    }
  }

  fetchAssignmentData(row) {
    return $.ajax({
      url: `/admin/reporting/gross_margins/${row.data('id')}`,
      method: "GET",
      error: () => {
        return this.renderFinancialData(row, false);
      },
      success: (data) => {
        return this.renderFinancialData(row, true, data);
      }
    });
  }

  monthPicker() {
    return $("#picker").flatpickr({
      locale: {
        rangeSeparator: ' - '
      },
      mode: "range",
      closeOnSelect: false,
      minDate: "2020-01",
      maxDate: new Date(),
      defaultDate: this.params.get("start_year") ? [new Date(this.params.get("start_year"), this.params.get("start_month") - 1), new Date(this.params.get("end_year"), (this.params.get("end_month") || this.params.get("start_month")) - 1)] : new Date().fp_incr(-60),
      onOpen: (selectedDates, dateStr, instance) => {
        jQuery("#picker").toggleClass("arrow-up");
        return instance.selectedDates = [];
      },
      onClose: (selectedDates, dateStr, instance) => {
        jQuery("#picker").toggleClass("arrow-up");
        if (selectedDates[0]) {
          this.params.set("start_year", selectedDates[0].getFullYear());
          this.params.set("start_month", selectedDates[0].getMonth() + 1);
          if (dateStr.indexOf('-') === -1) {
            this.params.delete("end_year");
            this.params.delete("end_month");
          } else {
            this.params.set("end_year", selectedDates[1].getFullYear());
            this.params.set("end_month", selectedDates[1].getMonth() + 1);
          }
          selectedDates[1] = selectedDates[1] || selectedDates[0];
          return this.updateData();
        }
      },
      onYearChange: (selectedDates, dateStr, instance) => {},
      // instance.selectedDates = []
      plugins: [
        new monthSelectPlugin({
          shorthand: true,
          dateFormat: "M Y"
        }),
        new confirmDatePlugin({
          confirmIcon: "",
          confirmText: "Apply",
          showAlways: true
        })
      ]
    });
  }

  renderFinancialData(row, succeeded, data = "") {
    row.find(".report-container").html(data);
    if (succeeded) {
      row.addClass("fetched");
    } else {
      this.exceptionMessage(true);
    }
    this.collapseAllRows();
    row.removeClass("loading collapsed");
    return this.scrollToRow(row);
  }

  scrollToRow(row) {
    jQuery(row)[0].scrollIntoView(true);
    return window.scrollBy(0, -30);
  }

  updateData() {
    var exportPath, path;
    $(".rails-container > .page-loader").css("display", "flex");
    exportPath = new URL(window.location.href).pathname + ".xlsx?" + this.params.toString();
    document.getElementById('export-filtered-data').href = exportPath;
    path = new URL(window.location.href).pathname + "?" + this.params.toString();
    window.history.pushState(path, jQuery("head title").html(), path);
    return jQuery.get(path + "&filter=true").done((data, textStatus, jqXHR) => {
      jQuery(".reporting-gross-margin").find(".report").replaceWith(data);
      return $(".page-loader").css("display", "none");
    });
  }

  exceptionMessage(show = true, reloading = false) {
    if (show) {
      this.row.find(".assignment-container").html("");
      this.row.find(".exception-message").css("display", "inline-block");
      if (reloading) {
        return this.row.find(".reloading").show();
      } else {
        return this.row.find(".reloading").hide();
      }
    } else {
      return this.row.find(".exception-message").hide();
    }
  }

  collapseAllRows() {
    return this.overview.find(".customer").addClass("collapsed");
  }

};

jQuery(function() {
  if (jQuery(".reporting-gross-margin").length > 0) {
    return new GrossMarginReporting(jQuery(".reporting-gross-margin")).monthPicker();
  }
});
