var Changelog;

Changelog = class Changelog {
  constructor() {
    this.initDatePicker = this.initDatePicker.bind(this);
    this.initDatePicker();
  }

  initDatePicker() {
    $(".datepicker-grid").datepicker({
      dateFormat: 'yy-mm-dd',
      numberOfMonths: 3,
      showCurrentAtPos: 1,
      beforeShow: function() {
        return $("#ui-datepicker-div").addClass("datepicker-grid-pop-up");
      },
      onSelect: function(dateText) {
        return window.location = "/admin/operations/assignments/changelog?date=" + dateText;
      }
    });
    return $(".datepicker-trigger").hover(function() {
      $(".datepicker-grid").datepicker("show");
      return $("#ui-datepicker-div").append("<div class=\"arrow\"></div>");
    });
  }

};

$(function() {
  if ($(".changelog-picker").length > 0) {
    return new Changelog();
  }
});
